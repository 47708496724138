import SwapVertIcon from "@mui/icons-material/SwapVert";

import styles from "./DividerMobile.module.scss";

function DividerMobile({ showSwapBtn, onSwap }) {
  return (
    <div className={styles.divider}>
      {showSwapBtn && (
        <button onClick={onSwap} className={styles.swapBtn}>
          <SwapVertIcon fontSize="medium" className={styles.icon} />
        </button>
      )}
    </div>
  );
}

export default DividerMobile;

import _ from "lodash";

import { setFilters, setDisplayData } from "../slice";
import getActualCompanyName from "../../utils/getActualCompanyName";
import sortFlightData from "../../utils/sortFlightData";

const filterCompanyAction = (states, companyName) => (dispatch) => {
  let filtersList = [...states.filterList];
  if (filtersList.includes(companyName)) {
    filtersList = filtersList.filter((c) => c !== companyName);
  } else {
    filtersList.push(companyName);
  }
  dispatch(setFilters(filtersList));

  const sortedData = sortFlightData(states.responseData, states.sortBy);

  let filteredData = sortedData.filter((f) =>
    _.includes(getActualCompanyName(filtersList), f.company)
  );
  dispatch(setDisplayData(filteredData));
};

export default filterCompanyAction;

import { useMemo } from "react";
import { Popper, Paper, Grid } from "@mui/material";

import { AIRPORTS } from "../../../common/constants/airports";
import sortDestination from "../utils/sortDestinations";

import styles from "./DestinationSelectPopperDesktop.module.scss";

function DestinationSelectPopperDesktop({
  anchorEl,
  headText,
  place,
  flipPlace,
  popularDestinations,
  allDestinations,
  onSelect,
}) {
  return (
    <Popper
      style={{ zIndex: 100000 }}
      placement={place}
      modifiers={[
        {
          name: "flip",
          enabled: true,
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
      ]}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
    >
      {({ placement }) => (
        <Paper
          className={styles.popperPaper}
          style={{
            marginBottom: placement === place ? "12px" : 0,
            marginTop: placement === flipPlace ? "12px" : 0,
          }}
        >
          <div
            className={[
              styles.container,
              allDestinations.length === 0 ? styles.emptyResultContainer : "",
            ].join(" ")}
          >
            <div className={styles.popperHeader}>
              <span className={styles.pillContainer}>Domestic</span>
              <span className={styles.headText}>{headText}</span>
            </div>
            <div className={styles.bodyContainer}>
              {allDestinations.length === 0 && (
                <p className={styles.noResultFoundText}>
                  We're sorry, airport could not be found!
                </p>
              )}
              {popularDestinations.length !== 0 && (
                <p className={styles.titleText}>Popular Destinations</p>
              )}
              {popularDestinations.map((airports, index) => (
                <p
                  onClick={() => onSelect(airports.CityName, airports.IATA)}
                  key={index}
                  className={styles.cities}
                >
                  {airports.CityName}
                </p>
              ))}
              {allDestinations.length !== 0 && (
                <p className={styles.titleText}>All Destinations</p>
              )}
              {allDestinations.map((airports, index) => (
                <p
                  onClick={() => onSelect(airports.CityName, airports.IATA)}
                  key={index}
                  className={styles.cities}
                >
                  {airports.CityName}
                </p>
              ))}
            </div>
          </div>
        </Paper>
      )}
    </Popper>
  );
}

export default DestinationSelectPopperDesktop;

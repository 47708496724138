import { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import formatParamData from "./utils/formatParamData";

import { resetStates } from "./redux/slice";
import getFlightListAction from "./redux/actions/getFlightListAction";
import getExchangeRateAction from "./redux/actions/getExchangeRateAction";
import sortFlightAction from "./redux/actions/sortFlightAction";
import filterCompanyAction from "./redux/actions/filterCompanyAction";

import HeadDetails from "./components/HeadDetails";
import AirlineFilter from "./components/AirlineFilter";
import B2BExchangeRate from "./components/B2BExchangeRate";
import CovidNotice from "./components/CovidNotice";
import FlightResult from "./components/FlightResult";
import NoFlight from "./components/NoFlight";
import Loading from "./components/Loading";

import styles from "./FlightList.module.scss";

function FlightList({ editSearchComponent }) {
  // Redux
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.flightList);

  const routeParams = useParams();

  const [showEditSearch, setShowEditSearch] = useState(false);

  const formattedData = useMemo(
    () =>
      formatParamData({
        flightRoute: routeParams.flightRoute,
        departureDate: routeParams.departureDate,
        returnDate: routeParams.returnDate,
        tripType: routeParams.tripType,
        nationality: routeParams.nationality,
        passengers: routeParams.passengers,
      }),
    [routeParams]
  );

  useEffect(() => {
    setShowEditSearch(false);
    dispatch(resetStates());
    dispatch(
      getFlightListAction({
        fromIAIT: formattedData.fromIATA,
        toIAIT: formattedData.toIATA,
        depDate: formattedData.depDateAPI,
        adultCount: formattedData.adultCount,
        childCount: formattedData.childCount,
        infantCount: formattedData.infantCount,
        nationality: formattedData.citizen,
      })
    );
    dispatch(getExchangeRateAction());
  }, [routeParams]);

  useEffect(() => {
    return () => dispatch(resetStates());
  }, []);

  return (
    <div className={styles.container}>
      <HeadDetails
        fromDestination={formattedData.fromDestination}
        toDestination={formattedData.toDestination}
        depDate={formattedData.depDate}
        retDate={formattedData.retDate}
        tripType={formattedData.trip}
        adultCount={formattedData.adultCount}
        childCount={formattedData.childCount}
        infantCount={formattedData.infantCount}
        value={reduxStates.sortBy}
        onSort={(value) =>
          dispatch(sortFlightAction(value, reduxStates.responseData))
        }
        onEditBtnClick={() => setShowEditSearch((prev) => !prev)}
      />
      <AirlineFilter
        onSelect={(companyName) => {
          dispatch(filterCompanyAction(reduxStates, companyName));
        }}
        filterList={reduxStates.filterList}
      />

      {showEditSearch && (
        <div className={styles.editSearchContainer}>{editSearchComponent}</div>
      )}

      <CovidNotice />
      {reduxStates.displayData === null && <Loading />}
      {reduxStates.displayData && (
        <FlightResult
          data={reduxStates.displayData}
          exchangeRate={reduxStates.exchangeRate}
        />
      )}
      {reduxStates.displayData && reduxStates.displayData.length === 0 && (
        <NoFlight onModifySearchClick={setShowEditSearch.bind(true)} />
      )}
    </div>
  );
}

export default FlightList;

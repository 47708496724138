import styles from "./SearchBtnEditSearch.module.scss";

function SearchBtnEditSearch({ onClick, id }) {
  return (
    <button id={id} onClick={onClick} className={styles.btn}>
      Search
    </button>
  );
}

export default SearchBtnEditSearch;

import { gql } from "@apollo/client";

const LOGIN_QUERY = gql`
  mutation ($input: WebsiteLoginInput) {
    websiteLogin(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        company {
          id
          name
          people {
            id
            prefix
            name
            middle_name
            last_name
            phone
            email
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        people {
          id
          prefix
          name
          middle_name
          last_name
          phone
          email
          nationality {
            id
            name
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          company_people {
            id
            name
            class_type {
              id
            }
          }
        }
      }
    }
  }
`;

export default LOGIN_QUERY;

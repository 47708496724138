import moment from "moment";
import { AIRPORTS } from "../../../common/constants/airports";
import { ROUND_TRIP, ONE_WAY_TRIP } from "../constants/tripTypes";

function formatParamDataForState({
  flightRoute,
  departureDate,
  returnDate,
  tripType,
  nationality,
  passengers,
}) {
  const fromDestParam = flightRoute.split("-")[0];
  const toDestParam = flightRoute.split("-")[1];

  const fromDestination = AIRPORTS.find(
    ({ IATA }) => IATA === fromDestParam.toUpperCase()
  );

  const toDestination = AIRPORTS.find(
    ({ IATA }) => IATA === toDestParam.toUpperCase()
  );

  const [depDay, depMonth, depYear] = departureDate.split("-");
  const depDate = new Date(`${depYear}-${depMonth}-${depDay}`).toString();

  const trip = parseInt(tripType) === 0 ? ONE_WAY_TRIP : ROUND_TRIP;

  let retDate = moment(new Date(depDate)).add(1, "days").toDate().toString();
  if (parseInt(tripType) && returnDate != 0) {
    const [retDay, retMonth, retYear] = returnDate.split("-");
    retDate = new Date(`${retYear}-${retMonth}-${retDay}`).toString();
  }

  const myanmarCitizen = nationality === "m";
  const adultCount = parseInt(passengers.split("-")[0]);
  const childCount = parseInt(passengers.split("-")[1]);
  const infantCount = parseInt(passengers.split("-")[2]);

  return {
    fromDestination: {
      cityName: fromDestination.CityName,
      IATA: fromDestination.IATA,
    },
    toDestination: {
      cityName: toDestination.CityName,
      IATA: toDestination.IATA,
    },
    trip,
    depDate,
    retDate,
    myanmarCitizen,
    adultCount,
    childCount,
    infantCount,
  };
}

export default formatParamDataForState;

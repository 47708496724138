import { Navigate } from "react-router-dom";

import {
  ACCESS_TOKEN,
  COMPANY_ROUTE_NAME,
} from "../common/constants/loginCredentialsLocalStorageKeys";

function UnauthorizeOnlyRoute({ children, path }) {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken)
    return (
      <Navigate
        to={`/${localStorage.getItem(COMPANY_ROUTE_NAME)}`}
        replace={true}
      />
    );

  return <>{children}</>;
}

export default UnauthorizeOnlyRoute;

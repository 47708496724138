import {
  setAdultPassengerCount,
  setChildPassengerCount,
  setInfantPassengerCount,
} from "../slice";

import {
  ADULT_AGE_TYPE,
  CHILD_AGE_TYPE,
  INFANT_AGE_TYPE,
} from "../../constants/passengerAgeType";

const passengerCountAction = (ageType, value, prevState) => (dispatch) => {
  const { adultCount, childCount, infantCount } = prevState;
  const totalCount = adultCount + childCount + infantCount;

  switch (ageType) {
    case ADULT_AGE_TYPE:
      if (value < adultCount && value >= 1)
        return dispatch(setAdultPassengerCount(value));
      if (
        value > adultCount &&
        totalCount < 9 &&
        totalCount - adultCount + value <= 9
      )
        return dispatch(setAdultPassengerCount(value));
      break;
    case CHILD_AGE_TYPE:
      if (value < childCount && value >= 0)
        return dispatch(setChildPassengerCount(value));
      if (
        value > childCount &&
        totalCount < 9 &&
        totalCount - childCount + value <= 9
      )
        return dispatch(setChildPassengerCount(value));
      break;
    case INFANT_AGE_TYPE:
      if (value < infantCount && value >= 0)
        return dispatch(setInfantPassengerCount(value));
      if (
        value > infantCount &&
        value <= adultCount &&
        totalCount < 9 &&
        totalCount - infantCount + value <= 9
      )
        return dispatch(setInfantPassengerCount(value));
      break;
    default:
      break;
  }
};

export default passengerCountAction;

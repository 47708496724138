import { Navigate, Route, useParams } from "react-router-dom";

import {
  ACCESS_TOKEN,
  COMPANY_ROUTE_NAME,
} from "../common/constants/loginCredentialsLocalStorageKeys";

function AuthorizeOnlyRoute({ children, path }) {
  const routeParams = useParams();

  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const loginCompanyName = localStorage.getItem(COMPANY_ROUTE_NAME);

  if (!accessToken) return <Navigate to="/" replace={true} />;

  if (routeParams.companyName !== loginCompanyName)
    return <Navigate to={`/${loginCompanyName}`} replace={true} />;

  return <>{children}</>;
}

export default AuthorizeOnlyRoute;

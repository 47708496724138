import styles from "./NoFlight.module.scss";

function NoFlight({ onModifySearchClick }) {
  return (
    <div className={styles.container}>
      <p className={styles.headerText}>No flights available</p>
      <p className={styles.subHeaderText}>Tip: Please modify your search</p>
      <button onClick={onModifySearchClick}>Modify Search</button>
    </div>
  );
}

export default NoFlight;

import moment from "moment";

import { ONE_WAY_TRIP, ROUND_TRIP } from "../constants/tripTypes";

function handleSearch(reduxStates, navigate, companyName) {
  if (
    reduxStates.fromDestination.IATA === null ||
    reduxStates.toDestination.IATA === null
  )
    return;

  const fromDestination = reduxStates.fromDestination.IATA.toLowerCase();
  const toDestination = reduxStates.toDestination.IATA.toLowerCase();
  const departureDate = moment(new Date(reduxStates.departureDate)).format(
    "DD-MM-YYYY"
  );
  const nationality = reduxStates.myanmarCitizen ? "m" : "n";
  const tripType = reduxStates.tripType === ONE_WAY_TRIP ? 0 : 1;
  const returnDate = tripType
    ? moment(new Date(reduxStates.returnDate)).format("DD-MM-YYYY")
    : 0;
  const passengers = `${reduxStates.adultCount}-${reduxStates.childCount}-${reduxStates.infantCount}`;

  navigate(
    `/${companyName}/flight/${fromDestination}-${toDestination}/${departureDate}/${returnDate}/${nationality}/${tripType}/${passengers}`
  );
}

export default handleSearch;

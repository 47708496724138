import { Link } from "react-router-dom";

import {
  SUNFAR_ROUTE_NAME,
  COLUMBUS_ROUTE_NAME,
} from "../../common/constants/companyRouteNameConsts";

import sfLogo from "../../assets/images/navigationLogo/sfLogo.png";
import colLogo from "../../assets/images/navigationLogo/colLogo.png";
import flymyaLogoBlack from "../../assets/images/flymya-logo-black.svg";

import styles from "./NavbarFlightList.module.scss";

function NavbarFlightList({ companyName, logout }) {
  return (
    <nav className={styles.nav}>
      <div className={styles.imgLogoutBtnContainer}>
        <Link to={`/${companyName}`}>
          {companyName === SUNFAR_ROUTE_NAME && (
            <img className={styles.img} src={sfLogo} alt="sunfar Logo" />
          )}
          {companyName === COLUMBUS_ROUTE_NAME && (
            <img className={styles.img} src={colLogo} alt="columbus Logo" />
          )}
          {companyName !== COLUMBUS_ROUTE_NAME && companyName !== SUNFAR_ROUTE_NAME && <img className={styles.flymyaLogo} src={flymyaLogoBlack} alt="flymya-logo"/>}
        </Link>

        <div>{logout}</div>
      </div>
    </nav>
  );
}

export default NavbarFlightList;

import { SUNFAR_ROUTE_NAME, COLUMBUS_ROUTE_NAME } from "../../../common/constants/companyRouteNameConsts";

import customMatcher from "../../constants/mediaMatcher";

import styles from "./HomeNavSearchLayout.module.scss";

function HomeNavSearchLayout({ companyName, children }) {
  const backgroundImg = () => {
    switch(companyName){
      case SUNFAR_ROUTE_NAME:
        return styles.sfBGImg;
      case COLUMBUS_ROUTE_NAME:
        return styles.colBGImg;
      default:
        return styles.testUserBGImg;
    }
  };
  return (
    <customMatcher.Provider>
      <customMatcher.Matcher
        mobileTablet={<>{children}</>}
        desktop={
          <div
            className={[
              styles.container,
              backgroundImg()
            ].join(" ")}
          >
            {children}
          </div>
        }
      />
    </customMatcher.Provider>
  );
}

export default HomeNavSearchLayout;

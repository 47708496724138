import customMatcher from "../../common/constants/mediaMatcher";
import MobileDesign from "./UI/MobileDesign";
import DesktopFlightSearchDesign from "./UI/DesktopFlightSearchDesign";

function FlightSearch({ companyName }) {
  return (
    <customMatcher.Provider>
      <customMatcher.Matcher
        mobileTablet={<MobileDesign companyName={companyName} />}
        desktop={<DesktopFlightSearchDesign companyName={companyName} />}
      />
    </customMatcher.Provider>
  );
}

export default FlightSearch;

import _ from "lodash";
import moment from "moment";

import {
  LOWEST_PRICE,
  HIGHEST_PRICE,
  EARLIEST_DEPARTURE,
  FLIGHT_DURATION,
} from "../constants/sortFlightConsts";

const sortFlightData = (data, sortBy) => {
  let sortedData;
  switch (sortBy) {
    case LOWEST_PRICE:
      sortedData = _.orderBy(data, (flight) => {
        return (
          flight &&
          Number(_.min(flight.fareClasses.map((minFare) => Number(minFare.sp))))
        );
      });
      break;
    case HIGHEST_PRICE:
      sortedData = _.orderBy(
        data,
        (flight) => {
          return (
            flight &&
            Number(
              _.min(flight.fareClasses.map((minFare) => Number(minFare.sp)))
            )
          );
        },
        ["desc"]
      );
      break;
    case EARLIEST_DEPARTURE:
      sortedData = _.orderBy(
        data,
        (flight) => flight && moment(flight.departure_time, "hh:mm A").valueOf()
      );
      break;
    case FLIGHT_DURATION:
      sortedData = _.orderBy(data, (flight) => {
        var mapObj = {
          h: ":",
          m: "",
        };
        let duration = _.replace(flight.duration, " ", "");
        duration = _.replace(duration, /h|m/gi, (matched) => mapObj[matched]);
        let makeArr = duration.split(":");
        let arr = [];
        makeArr[0].length === 1
          ? arr.push(`0${makeArr[0]}`)
          : arr.push(makeArr[0]);
        makeArr[1].length === 1
          ? arr.push(`0${makeArr[1]}`)
          : arr.push(makeArr[1]);
        return flight && moment(arr.join(":"), "hh:mm").valueOf();
      });
      break;
    default:
      sortedData = data;
      break;
  }

  return sortedData;
};

export default sortFlightData;

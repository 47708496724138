import { Grid } from "@mui/material";

import styles from "./AirlinePartners.module.scss";

function AirlinePartners() {
  return (
    <Grid item xs={12} sm={12} className={styles.divAirlinePartner}>
      <div className={styles.container}>
        <span className={styles.airlinePartner}>Airline Partners</span>
        <div className={styles.bgWhite}>
          <div className={styles.airline_airkbz}></div>
          <div className={styles.airline_gma}></div>
          <div className={styles.airline_mna}></div>
          <div className={styles.airline_myp}></div>
          <div className={styles.airline_air_thanlwin}></div>
        </div>
      </div>
    </Grid>
  );
}
export default AirlinePartners;

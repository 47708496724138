import React from "react";
import { ClickAwayListener } from "@mui/material";

import styles from "./InputBoxEditSearchDesktopMobile.module.scss";

const InputBoxEditSearchDesktopMobile = React.forwardRef(
  (
    {
      label,
      value,
      readOnly,
      onClickAway = () => {},
      children,
      onClick,
      onChange = () => {},
      show = true,
    },
    ref
  ) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div ref={ref} className={styles.container}>
          {show && (
            <>
              <label>{label}</label>
              <input
                onChange={onChange}
                value={value || ""}
                readOnly={readOnly}
                onClick={onClick}
              />
            </>
          )}
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default InputBoxEditSearchDesktopMobile;

import { useState, useRef } from "react";

import { EMAIL } from "../../../../common/constants/loginCredentialsLocalStorageKeys";

import TextInput from "./components/TextInput";
import RememberMeCheckBox from "./components/RememberMeCheckBox";
import LoginButton from "./components/LoginButton";

import profileLogo from "../../../../assets/images/loginImgs/profileIcon.png";
import mailLogo from "../../../../assets/images/loginImgs/mailLogo.svg";
import passwordLogo from "../../../../assets/images/loginImgs/passwordLogo.svg";
import planeImg from "../../../../assets/images/loginImgs/plane.svg";

import styles from "./LoginForm.module.scss";

function LoginForm({
  isLoading,
  onFormSubmit,
  isEmailError,
  emailErrorMessage,
  isPasswordError,
  passwordErrorMessage,
  rememberMe,
  onRememberMeChange,
}) {
  const emailRef = useRef();
  const passwordRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(emailRef.current.value, passwordRef.current.value);
  };

  return (
    <div className={styles.loginFormContainer}>
      <img className={styles.planeImg} src={planeImg} alt="planeImg" />
      <img
        className={styles.profileLogoImg}
        src={profileLogo}
        alt="profileLogo"
      />
      <h2>USER LOGIN</h2>
      <form onSubmit={onSubmit}>
        <TextInput
          defaultValue={localStorage.getItem(EMAIL)}
          isError={isEmailError}
          errorMessage={emailErrorMessage}
          ref={emailRef}
          prefixLogo={mailLogo}
          placeholder="Email ID"
          inputType="email"
        />
        <TextInput
          ref={passwordRef}
          isError={isPasswordError}
          errorMessage={passwordErrorMessage}
          prefixLogo={passwordLogo}
          placeholder="Password"
          inputType="password"
        />
        <RememberMeCheckBox
          value={rememberMe}
          onChange={(value) => onRememberMeChange(value)}
        />
        <LoginButton isLoading={isLoading} />
      </form>
    </div>
  );
}

export default LoginForm;

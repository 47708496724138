import { EARLIEST_DEPARTURE } from "../constants/sortFlightConsts";
import {
  AIR_KBZ_FILTER,
  MNA_FILTER,
  GMA_FILTER,
  MYP_FILTER,
  ATL_FILTER,
} from "../constants/filterValuesConsts";

const states = {
  responseData: null,
  displayData: null,
  exchangeRate: null,
  sortBy: EARLIEST_DEPARTURE,
  filterList: [AIR_KBZ_FILTER, MNA_FILTER, GMA_FILTER, MYP_FILTER, ATL_FILTER],
};

export default states;

import { gql } from "@apollo/client";

const GET_EXCHANGE_RATE_BY_CODE = gql`
  query ($code: ID!) {
    exchangeRatesByCode(code: $code) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

export default GET_EXCHANGE_RATE_BY_CODE;

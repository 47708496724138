import EMAIL_REGEX from "../constants/emailRegex";

/**
 * @param {Object} inputs
 * @param {String} inputs.value - value to check
 * @param {Boolean} inputs.isEmail - check with EMAIL_REGEX or not
 * @callback input.onError - function to execute when input value is invalid
 * @callback input.onValid - function to execute when input value is valid
 * @returns {Boolean} Returns true if input value is invalid else false
 */
function checkLoginInput({ value, isEmail, onError, onValid }) {
  if (value.trim().length === 0) {
    onError(isEmail ? "Email is required." : "Password is required.");
    return true;
  } else {
    onValid();
  }

  if (isEmail && !EMAIL_REGEX.test(value)) {
    onError("Invalid Email.");
    return true;
  } else {
    onValid();
  }
  return false;
}

export default checkLoginInput;

import DetailsText from "./components/DetailsText";
import DropDownEditSearch from "./components/DropDownEditSearchBtn";

import styles from "./HeadDetails.module.scss";

function HeadDetails({
  fromDestination,
  toDestination,
  depDate,
  retDate,
  tripType,
  adultCount,
  childCount,
  infantCount,
  value,
  onSort,
  onEditBtnClick,
}) {
  return (
    <div className={styles.container}>
      <DetailsText
        fromDestination={fromDestination}
        toDestination={toDestination}
        depDate={depDate}
        retDate={retDate}
        tripType={tripType}
        adultCount={adultCount}
        childCount={childCount}
        infantCount={infantCount}
      />
      <DropDownEditSearch
        onEditBtnClick={onEditBtnClick}
        value={value}
        onChange={onSort}
      />
    </div>
  );
}

export default HeadDetails;

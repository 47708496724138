/**
 * Function to return url for login user.
 * @param {String} email 
 * @return {String} url param for login user
 */
function getLoginUserForUrl(email){
    if(email.includes("sunfar")) return "sf";
    if(email.includes("columbus")) return "col";
    return "tst";
}

export default getLoginUserForUrl;
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "./MobileDesign.module.scss";

import useSlideDialogModalTabMobileHook from "../hooks/slideDialogModalTabMobileHook";

import {
  resetState,
  setFromDestination,
  setToDestination,
  swapDestinations,
  setMyanmarCitizen,
  setTripType,
  setDepartureDate,
  setReturnDate,
} from "../redux/slice";
import passengerCountAction from "../redux/actions/passengerCountAction";
import { ROUND_TRIP, ONE_WAY_TRIP } from "../constants/tripTypes";

import handleSearch from "../utils/handleSearch";

import TabBar from "../components/TabBar";
import SearchInputMobile from "../components/SearchInputMobile";
import DividerMobile from "../components/DividerMobile";
import SearchBtnMobile from "../components/SearchBtnMobile";
import DestinationSelectSlideMobile from "../components/DestinationSelectSlideMobile";
import DatePickerDialogMobile from "../components/DatePickerDialogMobile";
import PassengerSelectDialogMobile from "../components/PassengerSelectDialogMobile";
import MyanmarCitizenMobile from "../components/MyanmarCitizenMobile";

function MobileDesign({ companyName }) {
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.flightSearch);

  // Modal,Slide,Tab and Dialog states
  const {
    states,
    handleTabChange,
    handleFromDestSlide,
    handleToDestSlide,
    handleDepDatePickerDialog,
    handleReturnDatePickerDialog,
    handlePassengerDialog,
  } = useSlideDialogModalTabMobileHook({
    initialTabIndex: reduxStates.tripType === ONE_WAY_TRIP ? 0 : 1,
  });

  /** Reset all redux states when component unmont */
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <>
      <DestinationSelectSlideMobile
        visible={states.visibleFromDestSelectSlide}
        onClose={handleFromDestSlide.bind(null, false)}
        onSelect={(cityName, IATA) => {
          dispatch(setFromDestination({ cityName, IATA }));
          handleFromDestSlide(false);
        }}
        title="Departure"
        searchPlaceHolder="Departure City or Airport"
      />
      <DestinationSelectSlideMobile
        visible={states.visibleToDestSelectSlide}
        onClose={handleToDestSlide.bind(null, false)}
        onSelect={(cityName, IATA) => {
          dispatch(setToDestination({ cityName, IATA }));
          handleToDestSlide(false);
        }}
        title="Arrival"
        searchPlaceHolder="Arrival City or Airport"
      />

      <DatePickerDialogMobile
        open={
          states.visibleDepDatePickerDialog ||
          states.visibleReturnDatePickerDialog
        }
        handleClose={() => {
          handleDepDatePickerDialog(false);
          handleReturnDatePickerDialog(false);
        }}
        tripType={reduxStates.tripType}
        depDate={reduxStates.departureDate}
        returnDate={reduxStates.returnDate}
        onDepDateChange={(dateString) => dispatch(setDepartureDate(dateString))}
        onReturnDateChange={(dateString) => dispatch(setReturnDate(dateString))}
      />

      <PassengerSelectDialogMobile
        onValueChange={(ageType, value) =>
          dispatch(passengerCountAction(ageType, value, reduxStates))
        }
        adultCount={reduxStates.adultCount}
        childCount={reduxStates.childCount}
        infantCount={reduxStates.infantCount}
        open={states.visiblePassengerDialog}
        onClose={handlePassengerDialog.bind(null, false)}
        onConfirm={handlePassengerDialog.bind(null, false)}
      />
      <MyanmarCitizenMobile
        onSelect={(value) => dispatch(setMyanmarCitizen(value))}
      />
      <div className={styles.card}>
        <TabBar
          setTabIndex={(index) => {
            handleTabChange(index);
            dispatch(setTripType(index === 0 ? ONE_WAY_TRIP : ROUND_TRIP));
          }}
          currentTabIndex={states.tabIndex}
        />
        <SearchInputMobile
          labelText="From"
          value={reduxStates.fromDestination.cityName || ""}
          onTap={handleFromDestSlide.bind(null, true)}
        />
        <DividerMobile
          showSwapBtn
          onSwap={() => dispatch(swapDestinations())}
        />
        <SearchInputMobile
          labelText="To"
          value={reduxStates.toDestination.cityName || ""}
          onTap={handleToDestSlide.bind(null, true)}
        />
        <DividerMobile />
        <SearchInputMobile
          labelText="Depature"
          value={moment(reduxStates.departureDate).format("ddd, DD MMM YYYY")}
          onTap={handleDepDatePickerDialog.bind(null, true)}
        />
        <DividerMobile />
        <SearchInputMobile
          labelText="Return"
          value={moment(reduxStates.returnDate).format("ddd, DD MMM YYYY")}
          disable={states.tabIndex === 0}
          onTap={handleReturnDatePickerDialog.bind(null, true)}
        />
        <DividerMobile />
        <SearchInputMobile
          labelText="Passenger"
          value={`${reduxStates.adultCount} Adult${
            reduxStates.childCount !== 0
              ? ", " + reduxStates.childCount + " Child"
              : ""
          }${
            reduxStates.infantCount !== 0
              ? ", " + reduxStates.infantCount + " Infant"
              : ""
          }
          
          `}
          onTap={handlePassengerDialog.bind(null, true)}
        />
      </div>
      <SearchBtnMobile
        id={reduxStates.tripType === ROUND_TRIP ? "round-search-btn":"one-way-search-btn"}
        onClick={handleSearch.bind(null, reduxStates, navigate, companyName)}
      />
    </>
  );
}

export default MobileDesign;

import { createSlice } from "@reduxjs/toolkit";

import states from "./states";

const languageSlice = createSlice({
  name: "language",
  initialState: states,
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload;
    },
    loadContents(state, action) {
      state.contents = action.payload;
    },
  },
});

export default languageSlice.reducer;

export const { changeLanguage, loadContents } = languageSlice.actions;

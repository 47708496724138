import { Select, MenuItem } from "@mui/material";

import {
  LOWEST_PRICE,
  HIGHEST_PRICE,
  EARLIEST_DEPARTURE,
  FLIGHT_DURATION,
} from "../../../constants/sortFlightConsts";

import styles from "./DropDownEditSearchBtn.module.scss";

function DropDownEditSearch({ value, onChange, onEditBtnClick }) {
  return (
    <div className={styles.selectEditBtnContainer}>
      <div className={styles.dropDownContainer}>
        <span>Sort By</span>
        <Select
          className={styles.selectBox}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <MenuItem value={LOWEST_PRICE}>Lowest Price</MenuItem>
          <MenuItem value={HIGHEST_PRICE}>Highest Price</MenuItem>
          <MenuItem value={EARLIEST_DEPARTURE}>Earliest Departure</MenuItem>
          <MenuItem value={FLIGHT_DURATION}>Flight Duration</MenuItem>
        </Select>
      </div>
      <button onClick={onEditBtnClick} className={styles.editBtn}>
        Edit Search
      </button>
    </div>
  );
}

export default DropDownEditSearch;

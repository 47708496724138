import styles from "./WhosGoingText.module.scss";

function WhosGoingText() {
  return (
    <div className={styles.container}>
      <p>Who's Going ?</p>
    </div>
  );
}

export default WhosGoingText;

import _ from "lodash";
import moment from "moment";

import { setDisplayData, setSortValue } from "../slice";

import {
  LOWEST_PRICE,
  HIGHEST_PRICE,
  EARLIEST_DEPARTURE,
  FLIGHT_DURATION,
} from "../../constants/sortFlightConsts";

import sortFlightData from "../../utils/sortFlightData";

const sortFlightAction = (sortBy, data) => (dispatch) => {
  const sortedData = sortFlightData(data, sortBy);

  dispatch(setDisplayData(sortedData));
  dispatch(setSortValue(sortBy));
};

export default sortFlightAction;

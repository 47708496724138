import customMatcher from "../../common/constants/mediaMatcher";
import DesktopDesign from "./UI/DesktopDesign";
import MobileDesign from "./UI/MobileDesign";

function Navbar({ companyName, logout }) {
  return (
    <customMatcher.Provider>
      <customMatcher.Matcher
        mobileTablet={
          <MobileDesign companyName={companyName} logout={logout} />
        }
        desktop={<DesktopDesign companyName={companyName} logout={logout} />}
      />
    </customMatcher.Provider>
  );
}

export default Navbar;

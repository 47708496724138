import { Remove, Add } from "@mui/icons-material";

import styles from "./Counter.module.scss";

function Counter({
  ageTypeText,
  noticeText,
  value,
  onChange,
  isDisable,
  onIncrease,
  onDecrease,
}) {
  return (
    <div className={styles.passengersCountContainer}>
      <div className={styles.textContainer}>
        <p className={styles.typeText}>{ageTypeText}</p>
        <p className={styles.noticeText}>{noticeText}</p>
      </div>
      <div className={styles.actionsInputContainer}>
        <button
          onClick={onDecrease}
          disabled={isDisable}
          className={styles.removeBtn}
        >
          <Remove fontSize="small" />
        </button>
        <input
          onChange={onChange}
          value={value}
          disabled={isDisable}
          type="number"
          max={9}
        />
        <button
          onClick={onIncrease}
          disabled={isDisable}
          className={styles.addBtn}
        >
          <Add fontSize="small" />
        </button>
      </div>
    </div>
  );
}

export default Counter;

import { useParams } from "react-router-dom";

import Navbar from "../features/navbar/Navbar";
import Logout from "../features/logout";
import FlightSearch from "../features/flightSearch/FlightSearch";
import HomeNavSearchLayout from "../common/components/HomeNavSearchLayout";
import Footer from "../features/footer/Footer";

import FlightHomeWelcomeText from "../common/components/FlightHomeWelcomeText";

function Home() {
  const routeParams = useParams();
  return (
    <>
      <HomeNavSearchLayout companyName={routeParams.companyName}>
        <Navbar logout={<Logout />} companyName={routeParams.companyName} />
        <FlightHomeWelcomeText companyName={routeParams.companyName} />
        <FlightSearch companyName={routeParams.companyName} />
      </HomeNavSearchLayout>
      <Footer />
    </>
  );
}

export default Home;

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import styles from "./MyanmarCitizenMobile.module.scss";

function MyanmarCitizenMobile({ onSelect }) {
  return (
    <div className={styles.container}>
      <p>Myanmar Citizen</p>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="1"
        onChange={(e) => onSelect(Boolean(parseInt(e.target.value)))}
        name="radio-buttons-group"
      >
        <FormControlLabel
          className={styles.label}
          value="1"
          control={<Radio size="small" />}
          label={<span className={styles.labelText}>Yes</span>}
        />
        <FormControlLabel
          value="0"
          style={{ marginLeft: "7px" }}
          control={<Radio size="small" />}
          label={<span className={styles.labelText}>No</span>}
        />
      </RadioGroup>
    </div>
  );
}

export default MyanmarCitizenMobile;

export const AIR_KBZ_FILTER = "Air KBZ";
export const MNA_FILTER = "MNA";
export const GMA_FILTER = "GMA";
export const MYP_FILTER = "MYP";
export const ATL_FILTER = "ALT";

export const actualCompanyNames = {
  [AIR_KBZ_FILTER]: "Air KBZ",
  [MNA_FILTER]: "Myanmar National Airlines",
  [GMA_FILTER]: "Golden Myanmar Airlines",
  [MYP_FILTER]: "Mann Yadanarpon Airlines",
  [ATL_FILTER]: "Air Thanlwin",
};

import { Checkbox } from "@mui/material";

import {
  AIR_KBZ_FILTER,
  MNA_FILTER,
  GMA_FILTER,
  MYP_FILTER,
  ATL_FILTER,
} from "../../../constants/filterValuesConsts";

import styles from "./FilterPopover.module.scss";

function FilterPopover({ filterList, onChange }) {
  return (
    <div className={styles.container}>
      <div className={styles.checkBoxTextContainer}>
        <Checkbox
          onChange={() => onChange(AIR_KBZ_FILTER)}
          checked={filterList.includes(AIR_KBZ_FILTER)}
          size="small"
        />
        <p>Air KBZ</p>
      </div>
      <div className={styles.checkBoxTextContainer}>
        <Checkbox
          onChange={() => onChange(MNA_FILTER)}
          checked={filterList.includes(MNA_FILTER)}
          size="small"
        />
        <p>MNA</p>
      </div>
      <div className={styles.checkBoxTextContainer}>
        <Checkbox
          onChange={() => onChange(GMA_FILTER)}
          checked={filterList.includes(GMA_FILTER)}
          size="small"
        />
        <p>GMA</p>
      </div>
      <div className={styles.checkBoxTextContainer}>
        <Checkbox
          onChange={() => onChange(MYP_FILTER)}
          checked={filterList.includes(MYP_FILTER)}
          size="small"
        />
        <p>MYP</p>
      </div>
      <div className={styles.checkBoxTextContainer}>
        <Checkbox
          onChange={() => onChange(ATL_FILTER)}
          checked={filterList.includes(ATL_FILTER)}
          size="small"
        />
        <p>ATL</p>
      </div>
    </div>
  );
}

export default FilterPopover;

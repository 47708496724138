import { useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  AIR_KBZ_FILTER,
  MNA_FILTER,
  GMA_FILTER,
  MYP_FILTER,
  ATL_FILTER,
} from "../../constants/filterValuesConsts";

import FilterPopover from "./components/FilterPopover";

import styles from "./AirlineFilter.module.scss";

function AirlineFilter({ filterList, onSelect }) {
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.iconFilterTextContainer}>
        <TuneIcon className={styles.filterIcon} />
        <p>Filters</p>
      </div>
      <span className={styles.horizontalLine}>|</span>

      <ClickAwayListener onClickAway={() => setOpenPopover(false)}>
        <div className={styles.filterPopoverContainer}>
          <button
            onClick={() => setOpenPopover(true)}
            className={styles.dropDownBtn}
          >
            <p>
              {filterList.length === 0 ? (
                <span className={styles.emptyText}>Airlines</span>
              ) : (
                ""
              )}
              {filterList.includes(AIR_KBZ_FILTER) ? AIR_KBZ_FILTER + ", " : ""}
              {filterList.includes(MNA_FILTER) ? MNA_FILTER + ", " : ""}
              {filterList.includes(GMA_FILTER) ? GMA_FILTER + ", " : ""}
              {filterList.includes(MYP_FILTER) ? MYP_FILTER + ", " : ""}
              {filterList.includes(ATL_FILTER) ? ATL_FILTER + ", " : ""}
            </p>

            <ArrowDropDownIcon />
          </button>
          {openPopover && (
            <FilterPopover onChange={onSelect} filterList={filterList} />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default AirlineFilter;

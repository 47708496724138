import _ from "lodash";

import styles from "./B2BExchangeRate.module.scss";

function B2BExchangeRate({ exchangeRate }) {
  return (
    <p className={styles.exchageText}>
      B2B Exchange Rate: MMK {exchangeRate ? exchangeRate.toLocaleString() : ""}
      <span>(အချိန်နှင့်အမျှ အပြောင်းအလဲ ရှိနိုင်ပါသည်)</span>
    </p>
  );
}

export default B2BExchangeRate;

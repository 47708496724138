export const AIRPORTS = [
  {
    CityId: 1,
    CityName: "Yangon, Rangoon (RGN)",
    HotelCityName: "Yangon",
    IATA: "RGN",
  },
  {
    CityId: 2,
    CityName: "Mandalay (MDL)",
    HotelCityName: "Mandalay",
    IATA: "MDL",
  },
  {
    CityId: 3,
    CityName: "Nay Pyi Taw (NYT)",
    HotelCityName: "Nay Pyi Taw",
    IATA: "NYT",
  },
  {
    CityId: 4,
    CityName: "Bagan, Nyaung U (NYU)",
    HotelCityName: "Bagan",
    IATA: "NYU",
  },
  {
    CityId: 5,
    CityName: "Inle, Heho (HEH)",
    HotelCityName: "Heho",
    IATA: "HEH",
  },
  {
    CityId: 6,
    CityName: "Kawthaung (KAW)",
    HotelCityName: "Kawthaung",
    IATA: "KAW",
  },
  {
    CityId: 7,
    CityName: "Tachileik (THL)",
    HotelCityName: "Tachileik",
    IATA: "THL",
  },
  { CityId: 8, CityName: "Sittwe (AKY)", HotelCityName: "Sittwe", IATA: "AKY" },
  {
    CityId: 9,
    CityName: "Myitkyina (MYT)",
    HotelCityName: "Myitkyina",
    IATA: "MYT",
  },
  { CityId: 10, CityName: "Bhamo (BMO)", HotelCityName: "Bhamo", IATA: "BMO" },
  {
    CityId: 11,
    CityName: "Kyaingtong (KET)",
    HotelCityName: "Kyaingtong",
    IATA: "KET",
  },
  {
    CityId: 12,
    CityName: "Lashio (LSH)",
    HotelCityName: "Lashio",
    IATA: "LSH",
  },
  { CityId: 13, CityName: "Putao (PBU)", HotelCityName: "Putao", IATA: "PBU" },
  {
    CityId: 14,
    CityName: "Kalaymyo (KMV)",
    HotelCityName: "Kalaymyo",
    IATA: "KMV",
  },
  { CityId: 15, CityName: "Myeik (MGZ)", HotelCityName: "Myeik", IATA: "MGZ" },
  {
    CityId: 16,
    CityName: "Kyaukphyu (KYP)",
    HotelCityName: "Kyaukphyu",
    IATA: "KYP",
  },
  { CityId: 17, CityName: "Dawei (TVY)", HotelCityName: "Dawei", IATA: "TVY" },
  {
    CityId: 18,
    CityName: "Monywa (NYW)",
    HotelCityName: "Monywa",
    IATA: "NYW",
  },
  {
    CityId: 19,
    CityName: "Homalin (HOX)",
    HotelCityName: "Homalin",
    IATA: "HOX",
  },
  {
    CityId: 20,
    CityName: "Ngapali, Thandwe (SNW)",
    HotelCityName: "Ngapali",
    IATA: "SNW",
  },
  { CityId: 21, CityName: "Ann (VBA)", HotelCityName: "Ann", IATA: "VBA" },
  {
    CityId: 23,
    CityName: "Bokpyin (VBP)",
    HotelCityName: "Bokpyin",
    IATA: "VBP",
  },
  {
    CityId: 24,
    CityName: "Khamti (KHM)",
    HotelCityName: "Khamti",
    IATA: "KHM",
  },
  {
    CityId: 25,
    CityName: "Mong Hsat (MOG)",
    HotelCityName: "Mong Hsat",
    IATA: "MOG",
  },
  {
    CityId: 26,
    CityName: "Mawlamyine (MNU)",
    HotelCityName: "Yangon",
    IATA: "MNU",
  },
  {
    CityId: 27,
    CityName: "Loikaw (LIW)",
    HotelCityName: "Loikaw",
    IATA: "LIW",
  },
  {
    CityId: 30,
    CityName: "Pathein (BSX)",
    HotelCityName: "Pathein",
    IATA: "BSX",
  },
  {
    CityId: 32,
    CityName: "Magway (MWQ)",
    HotelCityName: "Magway",
    IATA: "MWQ",
  },
  {
    CityId: 33,
    CityName: "Manaung (MGU)",
    HotelCityName: "Manaung",
    IATA: "MGU",
  },
];

import moment from "moment";

import { ONE_WAY_TRIP } from "../constants/tripTypes";

const states = {
  myanmarCitizen: true,
  fromDestination: { cityName: null, IATA: null },
  toDestination: { cityName: null, IATA: null },
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
  tripType: ONE_WAY_TRIP,
  departureDate: moment().add(1, "days").toDate().toString(),
  returnDate: moment().add(2, "days").toDate().toString(),
};

export default states;

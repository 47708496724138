import moment from "moment";
import _ from "lodash";

import { setResponseData, setDisplayData } from "../slice";

import flightDataApi from "../../api/flightDataApi";

const getFlightListAction = (data) => async (dispatch) => {
  try {
    const response = await flightDataApi({ ...data });

    const sortedFareResponse = [...response.data.flightRoute].map((flight) => {
      let sortedFareArray =
        flight.fareClasses.length > 0 &&
        _.orderBy(flight.fareClasses, (fare) => {
          return fare && _.toNumber(fare.sp);
        });
      return { ...flight, fareClasses: sortedFareArray };
    });

    dispatch(setResponseData(sortedFareResponse));
    const earliestFlight = _.orderBy(
      [...sortedFareResponse],
      (flight) => flight && moment(flight.departure_time, "hh:mm A").valueOf()
    );

    dispatch(setDisplayData(earliestFlight));
  } catch (e) {
    console.log(e);
  }
};

export default getFlightListAction;

import { gql } from "@apollo/client";

const GET_FLIGHT = gql`
  query flightRoute($filter: [filterInput]) {
    flightRoute(filter: $filter) {
      id
      company
      external_id
      from
      from_airport
      to
      to_airport
      duration
      via
      departure_date
      departure_time
      arrival_date
      arrival_time
      baggage_allowance
      last_modified_date
      status
      response_raw_data
      fareClasses {
        id
        flight_route_id
        airline_fare_name
        flymya_fare_name
        citizen
        currency
        mar_percentage
        availability
        adults
        children
        infants
        sp
        sf
        or
        gb
        nb
        tf
        mar
        property
        fareBreakdowns {
          id
          flight_fare_class_id
          passenger_type
          currency
          or
          gb
          nb
          tf
          mar
          sp
          sf
        }
      }
    }
  }
`;

export default GET_FLIGHT;

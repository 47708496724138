import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { ROUND_TRIP, ONE_WAY_TRIP } from "../constants/tripTypes";

import styles from "./TripTypeRadioEditSearchDesktopMobile.module.scss";

function TripTypeRadioEditSearchDesktopMobile({ onSelect, value }) {
  return (
    <div className={styles.container}>
      <label className={styles.labelText}>Trip Type</label>
      <RadioGroup value={value} row onChange={(e) => onSelect(e.target.value)}>
        <FormControlLabel
          value={ONE_WAY_TRIP}
          control={<Radio size="small" />}
          label={<label className={styles.radioLabelText}>One Way</label>}
        />
        <FormControlLabel
          style={{ marginLeft: "3px" }}
          value={ROUND_TRIP}
          control={<Radio size="small" />}
          label={<label className={styles.radioLabelText}>Round Trip</label>}
        />
      </RadioGroup>
    </div>
  );
}

export default TripTypeRadioEditSearchDesktopMobile;

import { Add, Remove } from "@mui/icons-material";

import styles from "./Counter.module.scss";

function Counter({ ageTypeText, noticeText, value, onIncrement, onDecrement }) {
  return (
    <div className={styles.counterContainer}>
      <p className={styles.typeText}>{ageTypeText}</p>
      <p className={styles.noticeText}>{noticeText}</p>
      <div className={styles.actionsContainer}>
        <button onClick={onDecrement} className={styles.decrementBtn}>
          <Remove fontSize="medium" />
        </button>
        <p>{value}</p>
        <button onClick={onIncrement} className={styles.incrementBtn}>
          <Add fontSize="medium" />
        </button>
      </div>
    </div>
  );
}

export default Counter;

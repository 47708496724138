import clientV3 from "../../../apollo/client";

import LOGIN_QUERY from "../queries/loginQuery";

function loginApi(email, password) {
  return clientV3.mutate({
    mutation: LOGIN_QUERY,
    variables: {
      input: {
        username: email,
        password,
        hostname: "mm-flights.com",
      },
    },
  });
}

export default loginApi;

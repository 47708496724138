/**
 * Function to sort airport destination list alphabetical order
 * @param {Array<{CityId, CityName, HotelCityName, IATA}>} airportsList
 * @return {Array<{CityId, CityName, HotelCityName, IATA}>} returns sorted list
 */
function sortDestination(airportList) {
  return airportList.sort((a, b) => {
    if (a.CityName > b.CityName) return 1;
    if (a.CityName < b.CityName) return -1;
    return 0;
  });
}

export default sortDestination;

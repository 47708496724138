import React from "react";

import styles from "./TextInput.module.scss";

const TextInput = React.forwardRef(
  (
    { prefixLogo, placeholder, inputType, isError, errorMessage, defaultValue },
    ref
  ) => {
    return (
      <div>
        <div
          className={[
            styles.inputIconContainer,
            isError ? styles.errorBorder : "",
          ].join(" ")}
        >
          <img src={prefixLogo} />
          <input
            defaultValue={defaultValue || ""}
            ref={ref}
            placeholder={placeholder}
            type={inputType}
          />
        </div>
        {isError && <p className={styles.errorText}>{errorMessage}</p>}
      </div>
    );
  }
);

export default TextInput;

import { Modal } from "@mui/material";

import {
  ADULT_AGE_TYPE,
  CHILD_AGE_TYPE,
  INFANT_AGE_TYPE,
} from "../../constants/passengerAgeType";

import PassengerTextTop from "./components/PassengerTextTop";
import WhosGoingText from "./components/WhosGoingText";
import Counter from "./components/Counter";

import styles from "./PassengerSelectDialogMobile.module.scss";

function PassengerSelectDialogMobile({
  open,
  onClose,
  onValueChange,
  onConfirm,
  adultCount,
  childCount,
  infantCount,
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.container}>
        <PassengerTextTop />
        <WhosGoingText />
        <div className={styles.bodyContainer}>
          <Counter
            value={adultCount}
            onIncrement={() => onValueChange(ADULT_AGE_TYPE, adultCount + 1)}
            onDecrement={() => onValueChange(ADULT_AGE_TYPE, adultCount - 1)}
            ageTypeText="Adult"
            noticeText="(Age 12 and over)"
          />
          <hr />
          <Counter
            value={childCount}
            onIncrement={() => onValueChange(CHILD_AGE_TYPE, childCount + 1)}
            onDecrement={() => onValueChange(CHILD_AGE_TYPE, childCount - 1)}
            ageTypeText="Child"
            noticeText="(Age 2-11)"
          />
          <hr />
          <Counter
            value={infantCount}
            onIncrement={() => onValueChange(INFANT_AGE_TYPE, infantCount + 1)}
            onDecrement={() => onValueChange(INFANT_AGE_TYPE, infantCount - 1)}
            ageTypeText="Infant"
            noticeText="(Below Age 2)"
          />
          <div className={styles.btnContainer}>
            <button onClick={onConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PassengerSelectDialogMobile;

import { Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";

import baggageIcon from "../../../../../assets/images/blank-checked-baggage.svg";

import "./FlightDetails.scss";

const styleFont = { fontSize: "14px" };
const styleMargin = { marginTop: 6 };

function FlightDetails({
  airlineImage,
  airlineName,
  flightExternalID,
  departureTime,
  departureDate,
  arrivalTime,
  arrivalDate,

  fromAirport,

  toAirport,
}) {
  return (
    <Grid item sm={12} xs={12} className="fadein divflightDetails">
      <Grid container className="divInfo ">
        <Grid item xs={12} sm={4} md={3}>
          <div className="airlinLogo">
            <img width="70" src={airlineImage} alt="flight-logo" />
          </div>

          <div className="flightDetailInfo">
            <span className="airlineName">{airlineName}</span>
            <br />
            <span style={styleFont}>{flightExternalID}</span>

            <br />
            <span className="airlineClass">Economy</span>
          </div>
        </Grid>

        <Grid item sm={2} md={2} xs={5} style={styleMargin}>
          <span className="circle"></span>
          <div className="departAirline">
            <span className="departTime">{departureTime}</span>
            <br />
            <span className="airlnelbl">
              {moment(new Date(departureDate)).format("DD MMM YYYY")}
            </span>
          </div>
          <span className="circle-line"></span>
          <span className="fullCircle"></span>
          <div className="departAirline">
            <span className="departTime">{arrivalTime}</span>
            <br />
            <span className="airlnelbl">
              {moment(new Date(arrivalDate)).format("DD MMM YYYY")}
            </span>
          </div>
        </Grid>
        <Grid item md={4} sm={3} xs={7}>
          <div className="divDepart">
            <span className="departFlightLbl">{fromAirport}</span>
            <br />
            <span className="airlnelbl">{fromAirport} Airport</span>
          </div>

          <div className="divArrival">
            <span className="departFlightLbl">{toAirport}</span>
            <br />
            <span className="airlnelbl">{toAirport} Airport</span>
          </div>
        </Grid>
        <Grid item sm={3} md={3} xs={10}>
          <img src={baggageIcon} alt="Buggage" className="baggage_icon" />
          <span className="baggage_kilo">20</span>
          <div className="baggageAllowance">
            Baggage allowance
            <span className="kilo"> 20kg</span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FlightDetails;

import React from "react";
import { ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import styles from "./PassengerSelectedDisplayDesktop.module.scss";

const PassengerSelectedDisplayDesktop = React.forwardRef(
  (
    {
      className,
      adultCount,
      childCount,
      infantCount,
      onClickAway,
      onClick,
      children,
    },
    ref
  ) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div ref={ref} className={className}>
          <button onClick={onClick} className={styles.container}>
            <p>
              Passengers: {adultCount} Adult,
              {childCount > 0 ? `${childCount} Child,` : ""}{" "}
              {infantCount > 0 ? `${infantCount} Infant` : ""}
            </p>
            <ArrowDropDownIcon className={styles.icon} />
          </button>
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default PassengerSelectedDisplayDesktop;

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import styles from "./MyanmarCitizenDesktop.module.scss";

function MyanmarCitizenDesktop({ className, onSelect }) {
  return (
    <div className={[className, styles.container].join(" ")}>
      <p>Myanmar Citizen</p>
      <RadioGroup
        defaultValue="1"
        row
        onChange={(e) => onSelect(Boolean(parseInt(e.target.value)))}
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label={<span className={styles.labelText}>Yes</span>}
        />
        <FormControlLabel
          value="0"
          style={{ marginLeft: "7px" }}
          control={<Radio size="small" />}
          label={<span className={styles.labelText}>No</span>}
        />
      </RadioGroup>
    </div>
  );
}

export default MyanmarCitizenDesktop;

import moment from "moment";

import { AIRPORTS } from "../../../common/constants/airports";

/**
 * Function to format param data for Flight List feature
 */
function formatParamData({
  flightRoute,
  departureDate,
  returnDate,
  tripType,
  nationality,
  passengers,
}) {
  const fromDestParam = flightRoute.split("-")[0];
  const toDestParam = flightRoute.split("-")[1];

  const fromDestination = AIRPORTS.find(
    ({ IATA }) => IATA === fromDestParam.toUpperCase()
  ).CityName;

  const toDestination = AIRPORTS.find(
    ({ IATA }) => IATA === toDestParam.toUpperCase()
  ).CityName;

  const [depDay, depMonth, depYear] = departureDate.split("-");
  const depDate = moment(new Date(`${depYear}-${depMonth}-${depDay}`)).format(
    "dddd, DD MMM YYYY"
  );
  const depDateAPI = moment(
    new Date(`${depYear}-${depMonth}-${depDay}`)
  ).format("YYYY-MM-DD");
  const trip = parseInt(tripType) === 0 ? "One Way Trip" : "Round Trip";

  let retDate = null;
  if (parseInt(tripType) && returnDate != 0) {
    const [retDay, retMonth, retYear] = returnDate.split("-");
    retDate = moment(new Date(`${retYear}-${retMonth}-${retDay}`)).format(
      "dddd, DD MMM YYYY"
    );
  }

  const citizen = nationality === "m" ? "myanmar" : "non-myanmar";

  const adultCount = parseInt(passengers.split("-")[0]);
  const childCount = parseInt(passengers.split("-")[1]);
  const infantCount = parseInt(passengers.split("-")[2]);

  return {
    fromDestination,
    fromIATA: fromDestParam,
    toDestination,
    toIATA: toDestParam,
    depDate,
    depDateAPI,
    retDate,
    trip,
    citizen,
    adultCount,
    childCount,
    infantCount,
  };
}

export default formatParamData;

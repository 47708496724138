import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import editFlightSearchMatcher from "./constants/editFlightSearchMediaMatcher";

import { loadStatesFromRouteParam } from "./redux/slice";

import formatParamDataForState from "./utils/formatParamDataforState";

import EditSearchDesktopDesign from "./UI/EditSearchDesktopDesign";
import EditSearchMobileDesign from "./UI/EditSearchMobileDesign";

function EditSearch({ companyName }) {
  const dispatch = useDispatch();
  const routeParams = useParams();

  useEffect(() => {
    dispatch(
      loadStatesFromRouteParam(
        formatParamDataForState({
          flightRoute: routeParams.flightRoute,
          departureDate: routeParams.departureDate,
          returnDate: routeParams.returnDate,
          tripType: routeParams.tripType,
          nationality: routeParams.nationality,
          passengers: routeParams.passengers,
        })
      )
    );
  }, [routeParams]);

  return (
    <editFlightSearchMatcher.Provider>
      <editFlightSearchMatcher.Matcher
        mobileTablet={<EditSearchMobileDesign companyName={companyName} />}
        desktop={<EditSearchDesktopDesign companyName={companyName} />}
      />
    </editFlightSearchMatcher.Provider>
  );
}

export default EditSearch;

import React from "react";
import { ClickAwayListener } from "@mui/material";

import datePickerLogo from "../../../assets/images/flightSearchLogos/date-pick.png";

import styles from "./DateSelectInputDesktop.module.scss";

const DateSelectInputDesktop = React.forwardRef(
  ({ value, label, onClickAway, onClick, children }, ref) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={styles.clickDetectContainer}>
          <button onClick={onClick} ref={ref} className={styles.container}>
            <img src={datePickerLogo} height="25px" width="25px" />
            <div className={styles.inputContainer}>
              <p className={styles.labelText}>{label}</p>
              <p className={styles.valueText}>{value}</p>
            </div>
          </button>

          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default DateSelectInputDesktop;

import { INITIAL } from "../../../common/constants/loadStatus";

const states = {
  emailError: false,
  emailErrorMessage: "",
  passwordError: false,
  passwordErrorMessage: "",
  status: INITIAL,
  rememberMe:false,
};

export default states;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ROUND_TRIP } from "../constants/tripTypes";

import {
  setFromDestination,
  setToDestination,
  swapDestinations,
  setDepartureDate,
  setReturnDate,
  setMyanmarCitizen,
  setEditSearchTripType,
} from "../redux/slice";
import passengerCountAction from "../redux/actions/passengerCountAction";

import handleSearch from "../utils/handleSearch";

import usePopperDesktopAnchor from "../hooks/popperDesktopAnchorsHook";
import useDestinationSearch from "../hooks/destinationSearchHook";

import InputBoxEditSearchDesktopDesktopMobile from "../components/InputBoxEditSearchDesktopMobile";
import MyanmarCitizenRadioEditSearchDesktopMobile from "../components/MyanmarCitizenRadioEditSearchDesktopMobile";
import TripTypeRadioEditSearchDesktopMobile from "../components/TripTypeRadioEditSearchDesktopMobile";
import PassengerCountDisplayBoxEditSearchDesktopMobile from "../components/PassengerCountDisplayBoxEditSearchDesktopMobile";
import SearchBtnEditSearch from "../components/SearchBtnEditSearch";
import DestinationSelectPopperDesktop from "../components/DestinationSelectPopperDesktop";
import DatePickerPopperDesktop from "../components/DatePickerPopperDesktop";
import PassengerSelectPopperDesktop from "../components/PassengerSelectPopperDesktop";

import styles from "./EditSearchDesktopDesign.module.scss";

function EditSearchDesktopDesign({ companyName }) {
  // REDUX
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.flightSearch);

  const navigate = useNavigate();

  // Popper anchors
  const {
    fromContainerRef,
    toContainerRef,
    departureDateContainerRef,
    returnDateContainerRef,
    passengersDisplayRef,
    anchorEl,
    handleFromDestAnchor,
    handleToDestAnchor,
    handleDepDateAnchor,
    handleReturnDateAnchor,
    handlePassengerAnchor,
  } = usePopperDesktopAnchor();

  // From Destination Search states Hook
  const {
    result: fromResult,
    searchKeyword: fromSearchKeyword,
    setSearchKeyword: setFromSearchKeyword,
    handleSearch: handleFromSearch,
  } = useDestinationSearch();

  // To Destination Search states Hook
  const {
    result: toResult,
    searchKeyword: toSearchKeyword,
    setSearchKeyword: setToSearchKeyword,
    handleSearch: handleToSearch,
  } = useDestinationSearch();

  const handleFromDestSelect = (cityName, IATA) => {
    setFromSearchKeyword(cityName);
    dispatch(setFromDestination({ cityName, IATA }));
  };

  const handleToDestSelect = (cityName, IATA) => {
    setToSearchKeyword(cityName);
    dispatch(setToDestination({ cityName, IATA }));
  };

  const handleFromDestClickAway = () => {
    if (!anchorEl.fromAnchorEl) return;
    handleFromDestAnchor(false);
    if (fromResult.allDestinationsResult.length === 1) {
      let cityName = fromResult.allDestinationsResult[0].CityName;
      let IATA = fromResult.allDestinationsResult[0].IATA;
      setFromSearchKeyword(cityName);
      return dispatch(setFromDestination({ cityName, IATA }));
    }

    if (fromSearchKeyword.trim().length === 0)
      return dispatch(setFromDestination({ cityName: null, IATA: null }));
  };

  const handleToDestClickAway = () => {
    if (!anchorEl.toAnchorEl) return;
    handleToDestAnchor(false);
    if (toResult.allDestinationsResult.length === 1) {
      let cityName = toResult.allDestinationsResult[0].CityName;
      let IATA = toResult.allDestinationsResult[0].IATA;
      setToSearchKeyword(cityName);
      return dispatch(setToDestination({ cityName, IATA }));
    }

    if (toSearchKeyword.trim().length === 0)
      return dispatch(setToDestination({ cityName: null, IATA: null }));
  };

  useEffect(() => {
    setFromSearchKeyword(reduxStates.fromDestination.cityName || "");
    setToSearchKeyword(reduxStates.toDestination.cityName || "");
  }, [reduxStates]);

  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        <InputBoxEditSearchDesktopDesktopMobile
          value={fromSearchKeyword}
          label="From"
          ref={fromContainerRef}
          onClickAway={handleFromDestClickAway}
          onClick={handleFromDestAnchor.bind(null, true)}
          onChange={(e) => handleFromSearch(e.target.value)}
        >
          <DestinationSelectPopperDesktop
            anchorEl={anchorEl.fromAnchorEl}
            place="bottom"
            flipPlace="top"
            headText={`From ${fromSearchKeyword}`}
            popularDestinations={fromResult.popularDestinationsResult}
            allDestinations={fromResult.allDestinationsResult}
            onSelect={handleFromDestSelect}
          />
        </InputBoxEditSearchDesktopDesktopMobile>
        <button
          onClick={() => dispatch(swapDestinations())}
          className={styles.iconBtn}
        >
          <SwapHorizIcon />
        </button>

        <InputBoxEditSearchDesktopDesktopMobile
          onClickAway={handleToDestClickAway}
          value={toSearchKeyword}
          ref={toContainerRef}
          label="To"
          onChange={(e) => handleToSearch(e.target.value)}
          onClick={handleToDestAnchor.bind(null, true)}
        >
          <DestinationSelectPopperDesktop
            anchorEl={anchorEl.toAnchorEl}
            place="top"
            flipPlace="bottom"
            headText={`To ${toSearchKeyword}`}
            popularDestinations={toResult.popularDestinationsResult}
            allDestinations={toResult.allDestinationsResult}
            onSelect={handleToDestSelect}
          />
        </InputBoxEditSearchDesktopDesktopMobile>

        <InputBoxEditSearchDesktopDesktopMobile
          ref={departureDateContainerRef}
          onClickAway={handleDepDateAnchor.bind(null, false)}
          onClick={handleDepDateAnchor.bind(null, true)}
          value={moment(reduxStates.departureDate).format("ddd, DD MMM YYYY")}
          readOnly
          label="Departure Date"
        >
          <DatePickerPopperDesktop
            anchorEl={anchorEl.departureDateAnchorEl}
            secondAnchorEl={anchorEl.returnDateAnchorEl}
            headText="Departure Date"
            tripType={reduxStates.tripType}
            depDate={reduxStates.departureDate}
            returnDate={reduxStates.returnDate}
            onDepDateChange={(dateString) =>
              dispatch(setDepartureDate(dateString))
            }
            onReturnDateChange={(dateString) =>
              dispatch(setReturnDate(dateString))
            }
          />
        </InputBoxEditSearchDesktopDesktopMobile>
        <InputBoxEditSearchDesktopDesktopMobile
          show={reduxStates.tripType === ROUND_TRIP}
          ref={returnDateContainerRef}
          onClickAway={handleReturnDateAnchor.bind(null, false)}
          onClick={handleReturnDateAnchor.bind(null, true)}
          value={moment(reduxStates.returnDate).format("ddd, DD MMM YYYY")}
          readOnly
          label="Return Date"
        >
          <DatePickerPopperDesktop
            anchorEl={anchorEl.returnDateAnchorEl}
            secondAnchorEl={anchorEl.departureDateAnchorEl}
            headText="Return Date"
            tripType={reduxStates.tripType}
            depDate={reduxStates.departureDate}
            returnDate={reduxStates.returnDate}
            onDepDateChange={(dateString) =>
              dispatch(setDepartureDate(dateString))
            }
            onReturnDateChange={(dateString) =>
              dispatch(setReturnDate(dateString))
            }
          />
        </InputBoxEditSearchDesktopDesktopMobile>
      </div>
      <div className={styles.secondRow}>
        <MyanmarCitizenRadioEditSearchDesktopMobile
          onChange={(value) => dispatch(setMyanmarCitizen(value))}
          value={reduxStates.myanmarCitizen ? "1" : "0"}
        />
        <TripTypeRadioEditSearchDesktopMobile
          value={reduxStates.tripType}
          onSelect={(value) => dispatch(setEditSearchTripType(value))}
        />
        <PassengerCountDisplayBoxEditSearchDesktopMobile
          ref={passengersDisplayRef}
          adultCount={reduxStates.adultCount}
          childCount={reduxStates.childCount}
          infantCount={reduxStates.infantCount}
          onClickAway={handlePassengerAnchor.bind(null, false)}
          onClick={handlePassengerAnchor.bind(null, true)}
        >
          <PassengerSelectPopperDesktop
            onValueChange={(ageType, value) =>
              dispatch(passengerCountAction(ageType, value, reduxStates))
            }
            adultCount={reduxStates.adultCount}
            childCount={reduxStates.childCount}
            infantCount={reduxStates.infantCount}
            anchorEl={anchorEl.passengersAnchorEl}
          />
        </PassengerCountDisplayBoxEditSearchDesktopMobile>
        <SearchBtnEditSearch
          id={reduxStates.tripType === ROUND_TRIP ? "round-search-btn":"one-way-search-btn"}
          onClick={handleSearch.bind(null, reduxStates, navigate, companyName)}
        />
      </div>
    </div>
  );
}

export default EditSearchDesktopDesign;

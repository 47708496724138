import {
  ACCESS_TOKEN,
  EMAIL,
  COMPANY_ROUTE_NAME,
} from "../../../../common/constants/loginCredentialsLocalStorageKeys";
import {
  LOADING,
  LOADED,
  ERROR,
} from "../../../../common/constants/loadStatus";
import getLoginUserForUrl from "../../utils/getLoginUserForUrl";

import loginApi from "../../api/loginApi";

import {
  setEmailError,
  setEmailErrorMessage,
  setPasswordError,
  setPasswordErrorMessage,
  setStatusState,
} from "../slice";

import checkLoginInput from "../../utils/checkLoginInput";

const loginAction = (email, password, rememberME) => async (dispatch) => {
  const emailError = checkLoginInput({
    value: email,
    isEmail: true,
    onError: (message) => {
      dispatch(setEmailError(true));
      dispatch(setEmailErrorMessage(message));
    },
    onValid: () => {
      dispatch(setEmailError(false));
      dispatch(setEmailErrorMessage(""));
    },
  });

  const passwordError = checkLoginInput({
    value: password,
    isEmail: false,
    onError: (message) => {
      dispatch(setPasswordError(true));
      dispatch(setPasswordErrorMessage(message));
    },
    onValid: () => {
      dispatch(setPasswordError(false));
      dispatch(setPasswordErrorMessage(""));
    },
  });
  if (emailError || passwordError) return;

  dispatch(setStatusState(LOADING));
  try {
    const response = await loginApi(email, password);
    const data = response.data.websiteLogin;
    localStorage.setItem(ACCESS_TOKEN, data.access_token);
    if (rememberME) {
      localStorage.setItem(EMAIL, email);
    } else {
      localStorage.removeItem(EMAIL);
    }
    localStorage.setItem(
      COMPANY_ROUTE_NAME,
      getLoginUserForUrl(email)
      // email.includes("sunfar") ? "sf" : "col"
    );
    dispatch(setStatusState(LOADED));
  } catch (e) {
    /** Show red border lines without message */
    dispatch(setEmailError(true));
    dispatch(setPasswordError(true));

    dispatch(setStatusState(ERROR));
  }
};

export default loginAction;

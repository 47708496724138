import { useState, useMemo } from "react";
import { Slide, Paper, Grid } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

import { AIRPORTS } from "../../../common/constants/airports";
import sortDestination from "../utils/sortDestinations";
import useDestinationSearch from "../hooks/destinationSearchHook";

import styles from "./DestinationSelectSlideMobile.module.scss";

function DestinationSelectSlideMobile({
  visible,
  onClose,
  title,
  onSelect,
  searchPlaceHolder,
}) {
  const { result, handleSearch } = useDestinationSearch();

  return (
    <Slide className={styles.slideContainter} direction="up" in={visible}>
      <Paper>
        <div className={styles.popupSearch}>
          <div className={styles.closeBtnTitleContainer}>
            <button className={styles.closeIconBtn}>
              <Close className={styles.closeIcon} onClick={onClose} />
            </button>
            <p className={styles.searchTitle}>{title}</p>
          </div>

          <div className={styles.searchInputIconContainer}>
            <input
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={searchPlaceHolder}
              className={styles.searchInput}
            />
            <Search className={styles.searchIcon} />
          </div>
        </div>

        <Grid container>
          {result.allDestinationsResult.length === 0 && (
            <p className={styles.noResultText}>
              We're sorry, airport could not be found!
            </p>
          )}

          <Grid item xs={12} className={styles.airportListGroup}>
            {result.popularDestinationsResult.length > 0 && (
              <span className={styles.listHeaderTitle}>
                Popular Destinations
              </span>
            )}
            {result.popularDestinationsResult.length > 0 &&
              result.popularDestinationsResult.map((airport, index) => {
                return (
                  <Grid
                    item={true}
                    xs={12}
                    className={styles.airportList}
                    key={index}
                    onClick={() => onSelect(airport.CityName, airport.IATA)}
                  >
                    {airport.CityName}
                  </Grid>
                );
              })}
          </Grid>
          <Grid item xs={12} className={styles.airportListGroup}>
            {result.allDestinationsResult.length > 0 && (
              <span className={styles.listHeaderTitle}>All Destinations</span>
            )}
            {result.allDestinationsResult.map((airport, key) => (
              <Grid
                item={true}
                xs={12}
                className={styles.airportList}
                key={key}
                onClick={() => onSelect(airport.CityName, airport.IATA)}
              >
                {airport.CityName}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
}

export default DestinationSelectSlideMobile;

import React from "react";
import { TextField, ClickAwayListener } from "@mui/material";

import styles from "./SearchInputDesktop.module.scss";

const SearchInputDesktop = React.forwardRef(
  ({ onChange, value, onFocus, onClickAway, logo, label, children }, ref) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={styles.clickDetectContainer}>
          <div ref={ref} className={styles.logoInputContainer}>
            <img src={logo} height="18px" width="18px" />
            <TextField
              value={value}
              onFocus={onFocus}
              onChange={onChange}
              style={{ marginBottom: "12px", marginLeft: "12px" }}
              label={label}
              variant="standard"
              InputLabelProps={{ style: { color: "#a5a7a6" } }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          {/* Popper for destination select */}
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default SearchInputDesktop;

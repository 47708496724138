import _ from "lodash";

import FirstBreakDownBox from "./components/FirstBreakDownBox";
import OtherBreakDownBox from "./components/OtherBreakDownBox";

import styles from "./FareBreakdown.module.scss";

function FareBreakdown({ fareClasses, exchangeRate }) {
  return (
    <div className={styles.container}>
      {fareClasses.length > 0 && (
        <FirstBreakDownBox
          packageName={fareClasses[0].flymya_fare_name.toUpperCase()}
          farePriceCurrency={fareClasses[0].currency}
          farePrice={_.toNumber(fareClasses[0].or)}
          passengersPerPrice={fareClasses[0].fareBreakdowns}
          grossBase={_.toNumber(fareClasses[0].gb)}
          taxesFees={_.toNumber(fareClasses[0].tf)}
          exchangeRate={exchangeRate}
        />
      )}

      {fareClasses.length > 1 &&
        fareClasses
          .slice(1)
          .map((fare) => (
            <OtherBreakDownBox
              packageName={fare.flymya_fare_name.toUpperCase()}
              farePriceCurrency={fare.currency}
              farePrice={_.toNumber(fare.or)}
              passengersPerPrice={fare.fareBreakdowns}
              grossBase={_.toNumber(fare.gb)}
              taxesFees={_.toNumber(fare.tf)}
              exchangeRate={exchangeRate}
            />
          ))}
    </div>
  );
}

export default FareBreakdown;

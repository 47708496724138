import { useState } from "react";

function useSlideDialogModalTabMobileHook({ initialTabIndex }) {
  const [states, setStates] = useState({
    tabIndex: initialTabIndex,
    visibleFromDestSelectSlide: false,
    visibleToDestSelectSlide: false,
    visibleDepDatePickerDialog: false,
    visibleReturnDatePickerDialog: false,
    visiblePassengerDialog: false,
  });

  const handleTabChange = (tabIndex) =>
    setStates((prev) => {
      return { ...prev, tabIndex };
    });

  const handleFromDestSlide = (open) =>
    setStates((prev) => {
      return { ...prev, visibleFromDestSelectSlide: open };
    });

  const handleToDestSlide = (open) =>
    setStates((prev) => {
      return { ...prev, visibleToDestSelectSlide: open };
    });

  const handleDepDatePickerDialog = (open) =>
    setStates((prev) => {
      return { ...prev, visibleDepDatePickerDialog: open };
    });

  const handleReturnDatePickerDialog = (open) => {
    setStates((prev) => {
      return { ...prev, visibleReturnDatePickerDialog: open };
    });
  };

  const handlePassengerDialog = (open) =>
    setStates((prev) => {
      return { ...prev, visiblePassengerDialog: open };
    });

  return {
    states,
    handleTabChange,
    handleFromDestSlide,
    handleToDestSlide,
    handleDepDatePickerDialog,
    handleReturnDatePickerDialog,
    handlePassengerDialog,
  };
}

export default useSlideDialogModalTabMobileHook;

import { createSlice } from "@reduxjs/toolkit";

import states from "./states";

const loginSlice = createSlice({
  name: "login",
  initialState: states,
  reducers: {
    resetStates(state) {
      return (state = states);
    },
    setEmailError(state, action) {
      state.emailError = action.payload;
    },
    setEmailErrorMessage(state, action) {
      state.emailErrorMessage = action.payload;
    },
    setPasswordError(state, action) {
      state.passwordError = action.payload;
    },
    setPasswordErrorMessage(state, action) {
      state.passwordErrorMessage = action.payload;
    },
    setStatusState(state, action) {
      state.status = action.payload;
    },
    setRememberMe(state, action){
      state.rememberMe = action.payload;
    }
  },
});

export default loginSlice.reducer;
export const {
  resetStates,
  setEmailError,
  setEmailErrorMessage,
  setPasswordError,
  setPasswordErrorMessage,
  setStatusState,
  setRememberMe,
} = loginSlice.actions;

import { Popper, Paper } from "@mui/material";

import {
  ADULT_AGE_TYPE,
  CHILD_AGE_TYPE,
  INFANT_AGE_TYPE,
} from "../../constants/passengerAgeType";

import Counter from "./components/Counter";

import styles from "./PassengerSelectPopperDesktop.module.scss";

function PassengerSelectPopperDesktop({
  anchorEl,
  adultCount,
  childCount,
  infantCount,
  onValueChange,
}) {
  return (
    <Popper
      style={{ zIndex: 2 }}
      placement="bottom-start"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      <Paper>
        <div className={styles.container}>
          <Counter
            value={adultCount}
            onChange={(e) =>
              onValueChange(ADULT_AGE_TYPE, parseInt(e.target.value))
            }
            onIncrease={() => onValueChange(ADULT_AGE_TYPE, adultCount + 1)}
            onDecrease={() => onValueChange(ADULT_AGE_TYPE, adultCount - 1)}
            ageTypeText="Adult"
            noticeText="(Age 12 and over)"
          />
          <Counter
            value={childCount}
            onChange={(e) =>
              onValueChange(CHILD_AGE_TYPE, parseInt(e.target.value))
            }
            onIncrease={() => onValueChange(CHILD_AGE_TYPE, childCount + 1)}
            onDecrease={() => onValueChange(CHILD_AGE_TYPE, childCount - 1)}
            ageTypeText="Child"
            noticeText="(Age 2-11)"
            isDisable={adultCount === 9}
          />
          <Counter
            value={infantCount}
            onChange={(e) =>
              onValueChange(INFANT_AGE_TYPE, parseInt(e.target.value))
            }
            onIncrease={() => onValueChange(INFANT_AGE_TYPE, infantCount + 1)}
            onDecrease={() => onValueChange(INFANT_AGE_TYPE, infantCount - 1)}
            ageTypeText="Infant"
            noticeText="(Below Age 2)"
            isDisable={adultCount + childCount === 9}
          />
        </div>
      </Paper>
    </Popper>
  );
}

export default PassengerSelectPopperDesktop;

import { HashLoader } from "react-spinners";

import styles from "./LoginButton.module.scss";

function LoginButton({ isLoading }) {
  return (
    <button type="submit" className={styles.button}>
      {isLoading ? <HashLoader color="white" size={30} /> : "LOGIN"}
    </button>
  );
}

export default LoginButton;

import React from "react";
import { ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import styles from "./TripTypeSelectedDisplayDesktop.module.scss";

const TripTypeSelectedDisplayDesktop = React.forwardRef(
  ({ className, selectedTripType, onClickAway, onClick, children }, ref) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div ref={ref} className={className}>
          <button onClick={onClick} className={styles.container}>
            <p>{selectedTripType}</p>
            <ArrowDropDownIcon className={styles.icon} />
          </button>
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default TripTypeSelectedDisplayDesktop;

import { useState } from "react";
import _ from "lodash";

import getAirlineImg from "../../utils/getAirlineImg";

import CardDisplayData from "./components/CardDisplayData";
import TabButtons from "./components/TabButtons";
import FlightDetails from "./components/FlightDetails";
import FlarePackages from "./components/FarePackages";
import FareBreakdown from "./components/FareBreakdown";

import styles from "./FlightResult.module.scss";

function FlightResult({ data, exchangeRate }) {
  const [accordians, setAccordians] = useState({
    flightDetailAccordian: [],
    farePackageAccordian: [],
    farePackageBreakDownAccordian: [],
  });

  const handleFligtDetailClick = (index) =>
    setAccordians((prev) => {
      let deatailAccordianList = [...prev.flightDetailAccordian];
      deatailAccordianList[index] = !deatailAccordianList[index];
      return {
        flightDetailAccordian: deatailAccordianList,
        farePackageAccordian: [],
        farePackageBreakDownAccordian: [],
      };
    });

  return (
    <>
      {data.map((d, index) => (
        <div key={d.id} className={styles.container}>
          <CardDisplayData data={d} exchangeRate={exchangeRate}>
            <TabButtons
              index={index}
              flightDetailActiveStatus={accordians.flightDetailAccordian[index]}
              onFlightDetailClick={handleFligtDetailClick}
            />
          </CardDisplayData>

          {accordians.flightDetailAccordian[index] && (
            <FlightDetails
              airlineImage={getAirlineImg(d.company)}
              airlineName={d.company}
              flightExternalID={d.external_id}
              departureTime={d.departure_time}
              departureDate={d.departure_date}
              arrivalTime={d.arrival_time}
              arrivalDate={d.arrival_date}
              from={d.from}
              fromAirport={d.from_airport}
              to={d.to}
              toAirport={d.to_airport}
            />
          )}
        </div>
      ))}
    </>
  );
}

export default FlightResult;

import { configureStore } from "@reduxjs/toolkit";

import LoginReducer from "./features/login/redux/slice";
import flightSearchReducer from "./features/flightSearch/redux/slice";
import flightListReducer from "./features/flightList/redux/slice";
import languageReducer from "./features/language/redux/slice";

const store = configureStore({
  reducer: {
    login: LoginReducer,
    flightSearch: flightSearchReducer,
    flightList: flightListReducer,
    language: languageReducer,
  },
});

export default store;

import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import states from "./states";

const flightSearchSlice = createSlice({
  name: "flight-search",
  initialState: states,
  reducers: {
    resetState(state, action) {
      return (state = states);
    },
    loadStatesFromRouteParam(state, action) {
      const {
        fromDestination,
        toDestination,
        trip,
        depDate,
        retDate,
        myanmarCitizen,
        adultCount,
        childCount,
        infantCount,
      } = action.payload;

      return (state = {
        myanmarCitizen,
        fromDestination,
        toDestination,
        adultCount,
        childCount,
        infantCount,
        tripType: trip,
        departureDate: depDate,
        returnDate: retDate,
      });
    },
    setFromDestination(state, action) {
      const { cityName, IATA } = action.payload;
      state.fromDestination = { cityName, IATA };
    },
    setToDestination(state, action) {
      const { cityName, IATA } = action.payload;
      state.toDestination = { cityName, IATA };
    },
    swapDestinations(state, action) {
      const newStates = {
        ...state,
        fromDestination: state.toDestination,
        toDestination: state.fromDestination,
      };

      return (state = newStates);
    },
    setMyanmarCitizen(state, action) {
      state.myanmarCitizen = action.payload;
    },
    setAdultPassengerCount(state, action) {
      state.adultCount = action.payload;
    },
    setChildPassengerCount(state, action) {
      state.childCount = action.payload;
    },
    setInfantPassengerCount(state, action) {
      state.infantCount = action.payload;
    },
    setTripType(state, action) {
      const newStates = {
        ...state,
        tripType: action.payload,
        departureDate: moment().add(1, "days").toDate().toString(),
        returnDate: moment().add(2, "days").toDate().toString(),
      };
      return (state = newStates);
    },
    setEditSearchTripType(state, action) {
      state.tripType = action.payload;
    },
    setDepartureDate(state, action) {
      state.departureDate = action.payload;
    },
    setReturnDate(state, action) {
      state.returnDate = action.payload;
    },
  },
});

export default flightSearchSlice.reducer;
export const {
  resetState,
  loadStatesFromRouteParam,
  setFromDestination,
  setToDestination,
  swapDestinations,
  setMyanmarCitizen,
  setAdultPassengerCount,
  setChildPassengerCount,
  setInfantPassengerCount,
  setTripType,
  setEditSearchTripType,
  setDepartureDate,
  setReturnDate,
} = flightSearchSlice.actions;

import { useEffect, useState } from "react";
import { Popper, Paper } from "@mui/material";
import { DateRange, Calendar } from "react-date-range";
import moment from "moment";

import { ONE_WAY_TRIP, ROUND_TRIP } from "../constants/tripTypes";

import styles from "./DatePickerPopperDesktop.module.scss";

function DatePickerPopperDesktop({
  anchorEl,
  secondAnchorEl,
  headText,
  tripType,
  depDate,
  returnDate,
  onDepDateChange,
  onReturnDateChange,
}) {
  const [rangeDate, setRangeState] = useState([
    {
      startDate: new Date(depDate),
      endDate: new Date(returnDate),
      key: "selection",
    },
  ]);

  const [date, setDate] = useState(new Date(depDate));

  const handleDateChange = (date) => {
    onDepDateChange(date.toString());
  };

  const handleDateRangeChange = (item) => {
    onDepDateChange(item.selection.startDate.toString());
    onReturnDateChange(item.selection.endDate.toString());
  };

  useEffect(() => {
    if (tripType === ROUND_TRIP) {
      setRangeState([
        {
          startDate: new Date(depDate),
          endDate: new Date(returnDate),
          key: "selection",
        },
      ]);
    } else {
      setDate(new Date(depDate));
    }
  }, [depDate, returnDate, tripType]);

  return (
    <Popper
      placement="top-start"
      modifiers={[
        {
          name: "flip",
          enabled: true,
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "document",
            padding: 8,
          },
        },
      ]}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
    >
      <Paper
        className={styles.popperPaper}
        style={{
          marginBottom: "12px",
        }}
      >
        <div className={styles.container}>
          <div className={styles.popperHeader}>
            <span className={styles.pillContainer}>{tripType}</span>
            <span className={styles.headText}>{headText}</span>
          </div>
          <div>
            {tripType === ONE_WAY_TRIP && (
              <Calendar
                months={2}
                date={date}
                direction="horizontal"
                editableDateInputs={false}
                showMonthAndYearPickers={false}
                minDate={new Date()}
                onChange={handleDateChange}
              />
            )}
            {tripType === ROUND_TRIP && (
              <DateRange
                minDate={new Date()}
                editableDateInputs={false}
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                direction="horizontal"
                ranges={rangeDate}
                months={2}
              />
            )}
          </div>
        </div>
      </Paper>
    </Popper>
  );
}

export default DatePickerPopperDesktop;

import { SUNFAR_ROUTE_NAME } from "../../../common/constants/companyRouteNameConsts";

import customMatcher from "../../constants/mediaMatcher";
import styles from "./FlightHomeWelcomeText.module.scss";
import getWelcomText from "../../utils/getWelcomText";

function FlightHomeWelcomeText({ companyName }) {
  const {firstText, secondText} = getWelcomText(companyName);
  return (
    <customMatcher.Provider>
      <customMatcher.Matcher
        mobileTablet={<></>}
        desktop={
          <div
            className={
              companyName === SUNFAR_ROUTE_NAME
                ? styles.sfTextContainer
                : styles.colTextContainer
            }
          >
            <p className={styles.firstText}>{firstText}</p>
            <p className={styles.secondText}>{secondText}</p>
          </div>
        }
      />
    </customMatcher.Provider>
  );

}

export default FlightHomeWelcomeText;

import { Checkbox } from "@mui/material";

import styles from "./RememberMeCheckBox.module.scss";

function RememberMeCheckBox({ onChange, value }) {
  return (
    <div className={styles.container}>
      <Checkbox
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        style={{ paddingLeft: 0 }}
        size="small"
      />
      <p>Remember me</p>
    </div>
  );
}

export default RememberMeCheckBox;

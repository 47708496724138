import { Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import Home from "./pages/Home";
import FlightsList from "./pages/FlightsList";

import NotFound from "./features/404";

import AuthorizeOnlyRoute from "./protectRoutes/AuthorizeOnlyRoute";
import UnauthorizeOnlyRoute from "./protectRoutes/UnAuthorizeOnlyRoute";

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <UnauthorizeOnlyRoute>
            <LoginPage />
          </UnauthorizeOnlyRoute>
        }
      />
      <Route
        path="/:companyName"
        element={
          <AuthorizeOnlyRoute>
            <Home />
          </AuthorizeOnlyRoute>
        }
      />
      <Route
        path="/:companyName/flight/:flightRoute/:departureDate/:returnDate/:nationality/:tripType/:passengers"
        element={
          <AuthorizeOnlyRoute>
            <FlightsList />
          </AuthorizeOnlyRoute>
        }
      />
    </Routes>
  );
}

export default App;

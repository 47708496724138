import styles from "./SearchInputMobile.module.scss";

function SearchInputMobile({ labelText, value, disable, onTap }) {
  return (
    <button
      onClick={() => !disable && onTap()}
      className={[styles.container, disable ? styles.disable : ""].join(" ")}
    >
      <span className={styles.labelText}>{labelText}</span>
      <span className={styles.valueText}>{value}</span>
    </button>
  );
}

export default SearchInputMobile;

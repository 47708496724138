import styles from "./CovidNotice.module.scss";

function CovidNotice() {
  return (
    <div className={styles.container}>
      <p>
        Please be informed that all Domestic passengers (both vaccinated and
        non-vaccinated) must present
        <span>
          COVID-19 RTD Test negative result within 36 hours before departure at
          check-in counter
        </span>
        and on arrival according to the guidance of MOHS (Version 2.6) and local
        authorities
      </p>
      <p>
        This is effective starting from <span>15 Feb 2022</span> flights.
      </p>
      <p>
        MOHS (Version 2.6) အရ ပြည်တွင်း ခရီးစဉ်များ တွင် စီးနင်း လိုက်ပါမည့်
        ခရီးသည်များ (ကာကွယ်ဆေးထိုးပြီးသူများ နှင့် မထိုးရသေးသူများအားလုံး)
        <span>လေယာဉ်မထွက်ခွာမီ ၃၆ နာရီအတွင်း RTD test kit</span> နှင့်
        စစ်ဆေးထားသော <span>COVID-19 ကင်းစင်ကြောင်း ထောက်ခံချက်</span>{" "}
        ပါရှိရမည်ဖြစ်သည်။
      </p>
      <p>
        <span>၁၅.၂.၂၀၂၂</span> ပြည်တွင်း ခရီးစဉ်များမှ စ၍ အားလုံးအကျုံး၀င်သည်။
      </p>
    </div>
  );
}

export default CovidNotice;

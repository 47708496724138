import { useRef, useState } from "react";

function usePopperDesktopAnchor() {
  const [anchorEl, setAnchorEl] = useState({
    fromAnchorEl: null,
    toAnchorEl: null,
    departureDateAnchorEl: null,
    returnDateAnchorEl: null,
    passengersAnchorEl: null,
    tripTypeAnchorEl: null,
  });

  const fromContainerRef = useRef();
  const toContainerRef = useRef();
  const departureDateContainerRef = useRef();
  const returnDateContainerRef = useRef();
  const passengersDisplayRef = useRef();
  const tripTypeDisplayRef = useRef();

  const handleFromDestAnchor = (open) =>
    setAnchorEl((prev) => {
      return { ...prev, fromAnchorEl: open ? fromContainerRef.current : null };
    });

  const handleToDestAnchor = (open) =>
    setAnchorEl((prev) => {
      return { ...prev, toAnchorEl: open ? toContainerRef.current : null };
    });

  const handleDepDateAnchor = (open) =>
    setAnchorEl((prev) => {
      return {
        ...prev,
        departureDateAnchorEl: open ? departureDateContainerRef.current : null,
      };
    });
  const handleReturnDateAnchor = (open) =>
    setAnchorEl((prev) => {
      return {
        ...prev,
        returnDateAnchorEl: open ? returnDateContainerRef.current : null,
      };
    });
  const handlePassengerAnchor = (open) =>
    setAnchorEl((prev) => {
      return {
        ...prev,
        passengersAnchorEl: open ? passengersDisplayRef.current : null,
      };
    });

  const handleTripTypeAnchor = (open) =>
    setAnchorEl((prev) => {
      return {
        ...prev,
        tripTypeAnchorEl: open ? tripTypeDisplayRef.current : null,
      };
    });

  return {
    fromContainerRef,
    toContainerRef,
    departureDateContainerRef,
    returnDateContainerRef,
    passengersDisplayRef,
    tripTypeDisplayRef,
    anchorEl,
    handleFromDestAnchor,
    handleToDestAnchor,
    handleDepDateAnchor,
    handleReturnDateAnchor,
    handlePassengerAnchor,
    handleTripTypeAnchor,
  };
}
export default usePopperDesktopAnchor;

import clientV3 from "../../../apollo/client";
import GET_EXCHANGE_RATE_BY_CODE from "../queries/getExchangeRateQuery";

function exchangeRateApi() {
  return clientV3.query({
    query: GET_EXCHANGE_RATE_BY_CODE,
    fetchPolicy: "network-only",
    variables: {
      code: "MMK",
    },
  });
}

export default exchangeRateApi;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import fromFlightLogo from "../../../assets/images/flightSearchLogos/from.png";
import toFlightLogo from "../../../assets/images/flightSearchLogos/to.png";

import useDestinationSearch from "../hooks/destinationSearchHook";
import usePopperDesktopAnchor from "../hooks/popperDesktopAnchorsHook";

import {
  resetState,
  setFromDestination,
  setToDestination,
  swapDestinations,
  setMyanmarCitizen,
  setTripType,
  setDepartureDate,
  setReturnDate,
} from "../redux/slice";
import passengerCountAction from "../redux/actions/passengerCountAction";
import { ROUND_TRIP } from "../constants/tripTypes";

import handleSearch from "../utils/handleSearch";

import DestinationSelectPopperDesktop from "../components/DestinationSelectPopperDesktop";
import SearchInputDesktop from "../components/SearchInputDesktop";
import DividerDesktop from "../components/DividerDesktop";
import DateSelectInputDesktop from "../components/DateSelectInputDesktop";
import SearchBtnDesktop from "../components/SearchBtnDesktop";
import DatePickerPopperDesktop from "../components/DatePickerPopperDesktop";
import MyanmarCitizenDesktop from "../components/MyanmarCitizenDesktop";
import PassengerSelectedDisplayDesktop from "../components/PassengerSelectedDisplayDesktop";
import TripTypeSelectedDisplayDesktop from "../components/TripTypeSelectedDisplayDesktop";
import PassengerSelectPopperDesktop from "../components/PassengerSelectPopperDesktop";
import TripTypeSelectPopperDesktop from "../components/TripTypeSelectPopperDesktop";

import styles from "./DesktopFlightSearchDesign.module.scss";

function DesktopFlightSearchDesign({ companyName }) {
  const navigate = useNavigate();

  // REDUX
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.flightSearch);

  // Popper anchors
  const {
    fromContainerRef,
    toContainerRef,
    departureDateContainerRef,
    returnDateContainerRef,
    passengersDisplayRef,
    tripTypeDisplayRef,
    anchorEl,
    handleFromDestAnchor,
    handleToDestAnchor,
    handleDepDateAnchor,
    handleReturnDateAnchor,
    handlePassengerAnchor,
    handleTripTypeAnchor,
  } = usePopperDesktopAnchor();

  // From Destination Search states Hook
  const {
    result: fromResult,
    searchKeyword: fromSearchKeyword,
    setSearchKeyword: setFromSearchKeyword,
    handleSearch: handleFromSearch,
  } = useDestinationSearch();

  // To Destination Search states Hook
  const {
    result: toResult,
    searchKeyword: toSearchKeyword,
    setSearchKeyword: setToSearchKeyword,
    handleSearch: handleToSearch,
  } = useDestinationSearch();

  const handleFromDestSelect = (cityName, IATA) => {
    setFromSearchKeyword(cityName);
    dispatch(setFromDestination({ cityName, IATA }));
  };

  const handleToDestSelect = (cityName, IATA) => {
    setToSearchKeyword(cityName);
    dispatch(setToDestination({ cityName, IATA }));
  };

  const handleFromDestClickAway = () => {
    if (!anchorEl.fromAnchorEl) return;
    handleFromDestAnchor(false);
    if (fromResult.allDestinationsResult.length === 1) {
      let cityName = fromResult.allDestinationsResult[0].CityName;
      let IATA = fromResult.allDestinationsResult[0].IATA;
      setFromSearchKeyword(cityName);
      return dispatch(setFromDestination({ cityName, IATA }));
    }

    if (fromSearchKeyword.trim().length === 0)
      return dispatch(setFromDestination({ cityName: null, IATA: null }));
  };

  const handleToDestClickAway = () => {
    if (!anchorEl.toAnchorEl) return;
    handleToDestAnchor(false);
    if (toResult.allDestinationsResult.length === 1) {
      let cityName = toResult.allDestinationsResult[0].CityName;
      let IATA = toResult.allDestinationsResult[0].IATA;
      setToSearchKeyword(cityName);
      return dispatch(setToDestination({ cityName, IATA }));
    }

    if (toSearchKeyword.trim().length === 0)
      return dispatch(setToDestination({ cityName: null, IATA: null }));
  };

  const handleSwapDestinations = () => {
    dispatch(swapDestinations());
    setFromSearchKeyword(toSearchKeyword);
    setToSearchKeyword(fromSearchKeyword);
  };

  /** Reset all redux states when component unmont */
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.displaysContainer}>
          <MyanmarCitizenDesktop
            className={styles.display}
            onSelect={(value) => dispatch(setMyanmarCitizen(value))}
          />
          <PassengerSelectedDisplayDesktop
            ref={passengersDisplayRef}
            className={styles.display}
            adultCount={reduxStates.adultCount}
            childCount={reduxStates.childCount}
            infantCount={reduxStates.infantCount}
            onClickAway={handlePassengerAnchor.bind(null, false)}
            onClick={handlePassengerAnchor.bind(null, true)}
          >
            <PassengerSelectPopperDesktop
              onValueChange={(ageType, value) =>
                dispatch(passengerCountAction(ageType, value, reduxStates))
              }
              adultCount={reduxStates.adultCount}
              childCount={reduxStates.childCount}
              infantCount={reduxStates.infantCount}
              anchorEl={anchorEl.passengersAnchorEl}
            />
          </PassengerSelectedDisplayDesktop>
          <TripTypeSelectedDisplayDesktop
            onClick={handleTripTypeAnchor.bind(null, true)}
            onClickAway={handleTripTypeAnchor.bind(null, false)}
            className={styles.display}
            ref={tripTypeDisplayRef}
            selectedTripType={reduxStates.tripType}
          >
            <TripTypeSelectPopperDesktop
              onSelect={(type) => dispatch(setTripType(type))}
              selectedType={reduxStates.tripType}
              anchorEl={anchorEl.tripTypeAnchorEl}
            />
          </TripTypeSelectedDisplayDesktop>
        </div>
        <div className={styles.searchInputsContainer}>
          <SearchInputDesktop
            value={fromSearchKeyword}
            onClickAway={handleFromDestClickAway}
            ref={fromContainerRef}
            logo={fromFlightLogo}
            label="From"
            onChange={(e) => handleFromSearch(e.target.value)}
            onFocus={handleFromDestAnchor.bind(null, true)}
          >
            <DestinationSelectPopperDesktop
              anchorEl={anchorEl.fromAnchorEl}
              place="top"
              flipPlace="bottom"
              headText={`From ${fromSearchKeyword}`}
              popularDestinations={fromResult.popularDestinationsResult}
              allDestinations={fromResult.allDestinationsResult}
              onSelect={handleFromDestSelect}
            />
          </SearchInputDesktop>

          <DividerDesktop showSwapBtn onSwap={handleSwapDestinations} />

          <SearchInputDesktop
            onClickAway={handleToDestClickAway}
            ref={toContainerRef}
            logo={toFlightLogo}
            label="To"
            value={toSearchKeyword}
            onChange={(e) => handleToSearch(e.target.value)}
            onFocus={handleToDestAnchor.bind(null, true)}
          >
            <DestinationSelectPopperDesktop
              anchorEl={anchorEl.toAnchorEl}
              place="top"
              flipPlace="bottom"
              headText={`To ${toSearchKeyword}`}
              popularDestinations={toResult.popularDestinationsResult}
              allDestinations={toResult.allDestinationsResult}
              onSelect={handleToDestSelect}
            />
          </SearchInputDesktop>
          <DividerDesktop />

          <DateSelectInputDesktop
            ref={departureDateContainerRef}
            onClickAway={handleDepDateAnchor.bind(null, false)}
            onClick={handleDepDateAnchor.bind(null, true)}
            value={moment(reduxStates.departureDate).format("ddd, DD MMM YYYY")}
            label="Departure Date"
          >
            <DatePickerPopperDesktop
              anchorEl={anchorEl.departureDateAnchorEl}
              secondAnchorEl={anchorEl.returnDateAnchorEl}
              headText="Departure Date"
              tripType={reduxStates.tripType}
              depDate={reduxStates.departureDate}
              returnDate={reduxStates.returnDate}
              onDepDateChange={(dateString) =>
                dispatch(setDepartureDate(dateString))
              }
              onReturnDateChange={(dateString) =>
                dispatch(setReturnDate(dateString))
              }
            />
          </DateSelectInputDesktop>

          {reduxStates.tripType === ROUND_TRIP && (
            <>
              <DividerDesktop />
              <DateSelectInputDesktop
                ref={returnDateContainerRef}
                onClickAway={handleReturnDateAnchor.bind(null, false)}
                onClick={handleReturnDateAnchor.bind(null, true)}
                value={moment(reduxStates.returnDate).format(
                  "ddd, DD MMM YYYY"
                )}
                label="Return Date"
              >
                <DatePickerPopperDesktop
                  anchorEl={anchorEl.returnDateAnchorEl}
                  secondAnchorEl={anchorEl.departureDateAnchorEl}
                  headText="Return Date"
                  tripType={reduxStates.tripType}
                  depDate={reduxStates.departureDate}
                  returnDate={reduxStates.returnDate}
                  onDepDateChange={(dateString) =>
                    dispatch(setDepartureDate(dateString))
                  }
                  onReturnDateChange={(dateString) =>
                    dispatch(setReturnDate(dateString))
                  }
                />
              </DateSelectInputDesktop>
            </>
          )}

          <SearchBtnDesktop
            id={reduxStates.tripType === ROUND_TRIP ? "round-search-btn":"one-way-search-btn"}
            companyName={companyName}
            onClick={handleSearch.bind(
              null,
              reduxStates,
              navigate,
              companyName
            )}
          />
        </div>
      </div>
    </>
  );
}

export default DesktopFlightSearchDesign;

import flymyaLogo from "../../../assets/images/loginImgs/flymyaLogo.svg";

import styles from "./LoginHeaderImgText.module.scss";

function LoginHeaderImgText() {
  return (
    <div className={styles.headerImgTextContainer}>
      <img src={flymyaLogo} alt="flymyaLogo" />
      <h3>Flymya Flight Aggregator</h3>
    </div>
  );
}

export default LoginHeaderImgText;

import { SUNFAR_ROUTE_NAME } from "../../../common/constants/companyRouteNameConsts";

import styles from "./SearchBtnDesktop.module.scss";

function SearchBtnDesktop({ onClick, companyName,id }) {
  return (
    <button
      id={id}
      onClick={onClick}
      className={[
        styles.button,
        companyName === SUNFAR_ROUTE_NAME
          ? styles.sfBtnColor
          : styles.colBtnColor,
      ].join(" ")}
    >
      Search
    </button>
  );
}

export default SearchBtnDesktop;

import { createSlice } from "@reduxjs/toolkit";

import states from "./states";

const flightListSlice = createSlice({
  name: "flights-list",
  initialState: states,
  reducers: {
    resetStates(state) {
      return (state = states);
    },
    setResponseData(state, action) {
      state.responseData = action.payload;
    },
    setDisplayData(state, action) {
      state.displayData = action.payload;
    },
    setExchangeRate(state, action) {
      state.exchangeRate = action.payload;
    },
    setSortValue(state, action) {
      state.sortBy = action.payload;
    },
    setFilters(state, action) {
      state.filterList = action.payload;
    },
  },
});

export default flightListSlice.reducer;

export const {
  resetStates,
  setResponseData,
  setDisplayData,
  setExchangeRate,
  setSortValue,
  setFilters,
} = flightListSlice.actions;

import { useState, useEffect } from "react";
import { Dialog, Paper, DialogContent } from "@mui/material";
import { DateRange, Calendar } from "react-date-range";
import moment from "moment";

import { ROUND_TRIP, ONE_WAY_TRIP } from "../constants/tripTypes";

function DatePickerDialogMobile({
  open,
  handleClose,
  tripType,
  depDate,
  returnDate,
  onDepDateChange,
  onReturnDateChange,
}) {
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(depDate),
      endDate: new Date(returnDate),
      key: "selection",
    },
  ]);

  const [date, setDate] = useState(new Date(depDate));

  useEffect(() => {
    if (tripType === ROUND_TRIP) {
      setRangeDate([
        {
          startDate: new Date(depDate),
          endDate: new Date(returnDate),
          key: "selection",
        },
      ]);
    } else {
      setDate(new Date(depDate));
    }
  }, [depDate, returnDate, tripType]);

  const handleDateChange = (date) => {
    onDepDateChange(date.toString());
  };

  const handleDateRangeChange = (item) => {
    onDepDateChange(item.selection.startDate.toString());
    onReturnDateChange(item.selection.endDate.toString());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      style={{ width: "98%" }}
      open={open}
      onClose={handleClose}
    >
      {tripType === ONE_WAY_TRIP && (
        <Calendar
          minDate={new Date()}
          editableDateInputs={false}
          showMonthAndYearPickers={false}
          onChange={handleDateChange}
          date={date}
          direction="horizontal"
        />
      )}
      {tripType === ROUND_TRIP && (
        <DateRange
          minDate={new Date()}
          editableDateInputs={false}
          onChange={handleDateRangeChange}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          direction="horizontal"
          ranges={rangeDate}
          months={1}
        />
      )}
    </Dialog>
  );
}

export default DatePickerDialogMobile;

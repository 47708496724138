import _ from "lodash";

import getAirLineImg from "../../../utils/getAirlineImg";

import styles from "./CardDisplayData.module.scss";

function CardDisplayData({ children: tabBtnChild, data, exchangeRate }) {
  return (
    <div className={styles.detailsPricesContainer}>
      <div className={styles.detailsTabBtnContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.logoTimeNameContainer}>
            <img src={getAirLineImg(data.company)} />
            <div>
              <p className={styles.time}>
                {data.departure_time} - {data.arrival_time}
              </p>
              <p className={styles.secondaryText}>{data.company}</p>
            </div>
          </div>
          <div className={styles.hideMobile}>
            <p className={styles.titleText}>
              {data.from} - {data.to}
            </p>
            <p className={styles.secondaryText}>
              {data.from_airport} - {data.to_airport}
            </p>
          </div>
          <div className={styles.hideMobile}>
            <p className={styles.titleText}>{data.duration}</p>
            <p className={styles.secondaryText}>
              {data.via === "" ? "Direct" : ""}
            </p>
          </div>
        </div>

        {tabBtnChild}
      </div>

      <div className={styles.pricesContainer}>
        <div>
          <p className={styles.priceText}>
            MMK&nbsp;
            {data.fareClasses[0].currency === "USD"
              ? _.toNumber(data.fareClasses[0].or) * exchangeRate
              : _.toNumber(data.fareClasses[0].or).toLocaleString()}
          </p>
        </div>
        <div>
          <p className={styles.smallText}>Gross Base</p>
          <p className={styles.smallPriceText}>
            MMK&nbsp;
            {data.fareClasses[0].currency === "USD"
              ? _.toNumber(data.fareClasses[0].gb) * exchangeRate
              : _.toNumber(data.fareClasses[0].gb).toLocaleString()}
          </p>
          <p className={styles.smallText}>Taxes &#38; Fees</p>
          <p className={styles.smallPriceText}>
            MMK&nbsp;
            {data.fareClasses[0].currency === "USD"
              ? _.toNumber(data.fareClasses[0].tf) * exchangeRate
              : _.toNumber(data.fareClasses[0].tf).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardDisplayData;

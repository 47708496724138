import clientV3 from "../../../apollo/client";
import GET_FLIGHT from "../queries/getFlightQuery";

function flightDataApi({
  fromIAIT,
  toIAIT,
  depDate,
  adultCount,
  childCount,
  infantCount,
  nationality,
}) {
  return clientV3.query({
    query: GET_FLIGHT,
    fetchPolicy: "network-only",
    variables: {
      filter: [
        {
          field: "from",
          operator: "=",
          value: fromIAIT,
        },
        {
          conjunction: "AND",
          field: "to",
          operator: "=",
          value: toIAIT,
        },
        {
          conjunction: "AND",
          field: "departure_date",
          operator: "=",
          value: depDate,
        },
        {
          conjunction: "AND",
          field: "adults",
          operator: "=",
          value: `${adultCount}`,
        },
        {
          conjunction: "AND",
          field: "children",
          operator: "=",
          value: `${childCount}`,
        },
        {
          conjunction: "AND",
          field: "infants",
          operator: "=",
          value: `${infantCount}`,
        },
        {
          conjunction: "AND",
          field: "citizen",
          operator: "=",
          value: nationality,
        },
        {
          conjunction: "AND",
          field: "class_type_id",
          operator: "=",
          value: "1",
        },
        {
          conjunction: "AND",
          field: "airline_query",
          operator: "=",
          value: "true",
        },
      ],
      orderBy: [
        {
          field: "id",
          order: "DESC",
        },
      ],
      first: 50,
      page: 1,
    },
  });
}

export default flightDataApi;

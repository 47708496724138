import SwapHoriz from "@mui/icons-material/SwapHoriz";

import styles from "./DividerDesktop.module.scss";

function DividerDesktop({ showSwapBtn, onSwap }) {
  return (
    <div className={styles.divider}>
      {showSwapBtn && (
        <button onClick={onSwap} className={styles.swapBtn}>
          <SwapHoriz fontSize="medium" className={styles.icon} />
        </button>
      )}
    </div>
  );
}

export default DividerDesktop;

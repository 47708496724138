import { Link } from "react-router-dom";

import {
  SUNFAR_ROUTE_NAME,
  COLUMBUS_ROUTE_NAME,
} from "../../../common/constants/companyRouteNameConsts";

import styles from "./DesktopDesign.module.scss";

import sfLogo from "../../../assets/images/navigationLogo/sfLogo.png";
import colLogo from "../../../assets/images/navigationLogo/colLogo.png";
import flymyaLogoBlack from "../../../assets/images/flymya-logo-white.svg"

function DesktopDesign({ companyName, logout }) {
  return (
    <nav className={styles.nav}>
      <Link to="/">
        {companyName === SUNFAR_ROUTE_NAME && (
          <img className={styles.img} src={sfLogo} alt="sunfar-logo" />
        )}
        {companyName === COLUMBUS_ROUTE_NAME && (
          <img className={styles.img} src={colLogo} alt="colbums-logo" />
        )}
        {companyName !== SUNFAR_ROUTE_NAME && companyName !== COLUMBUS_ROUTE_NAME && <img className={styles.flymyaLogo} src={flymyaLogoBlack} alt="flymya-logo"/>}
      </Link>

      <div>{logout}</div>
    </nav>
  );
}

export default DesktopDesign;

import { useNavigate } from "react-router-dom";

import {
  ACCESS_TOKEN,
  COMPANY_ROUTE_NAME,
  EMAIL,
} from "../../common/constants/loginCredentialsLocalStorageKeys";

import styles from "./Logout.module.scss";

function Logout() {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(COMPANY_ROUTE_NAME);

    navigate("/", { replace: true });
  };

  return (
    <button onClick={handleClick} className={styles.logoutBtn}>
      Log Out
    </button>
  );
}

export default Logout;

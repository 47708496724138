import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSlideDialogModalTabMobileHook from "../hooks/slideDialogModalTabMobileHook";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import moment from "moment";

import {
  resetState,
  setFromDestination,
  setToDestination,
  swapDestinations,
  setMyanmarCitizen,
  setDepartureDate,
  setReturnDate,
  setEditSearchTripType,
} from "../redux/slice";
import passengerCountAction from "../redux/actions/passengerCountAction";

import { ROUND_TRIP } from "../constants/tripTypes";
import handleSearch from "../utils/handleSearch";

import InputBoxEditSearchDesktopMobile from "../components/InputBoxEditSearchDesktopMobile";
import MyanmarCitizenRadioEditSearchDesktopMobile from "../components/MyanmarCitizenRadioEditSearchDesktopMobile";
import TripTypeRadioEditSearchDesktopMobile from "../components/TripTypeRadioEditSearchDesktopMobile";
import PassengerCountDisplayBoxEditSearchDesktopMobile from "../components/PassengerCountDisplayBoxEditSearchDesktopMobile";
import SearchBtnEditSearch from "../components/SearchBtnEditSearch";
import DestinationSelectSlideMobile from "../components/DestinationSelectSlideMobile";
import DatePickerDialogMobile from "../components/DatePickerDialogMobile";
import PassengerSelectDialogMobile from "../components/PassengerSelectDialogMobile";

import styles from "./EditSearchMobileDesign.module.scss";

function EditSearchMobileDesign({ companyName }) {
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.flightSearch);
  // Modal,Slide,Tab and Dialog states
  const {
    states,
    handleFromDestSlide,
    handleToDestSlide,
    handleDepDatePickerDialog,
    handleReturnDatePickerDialog,
    handlePassengerDialog,
  } = useSlideDialogModalTabMobileHook({ initialTabIndex: 0 });
  return (
    <>
      <DestinationSelectSlideMobile
        visible={states.visibleFromDestSelectSlide}
        onClose={handleFromDestSlide.bind(null, false)}
        onSelect={(cityName, IATA) => {
          dispatch(setFromDestination({ cityName, IATA }));
          handleFromDestSlide(false);
        }}
        title="Departure"
        searchPlaceHolder="Departure City or Airport"
      />
      <DestinationSelectSlideMobile
        visible={states.visibleToDestSelectSlide}
        onClose={handleToDestSlide.bind(null, false)}
        onSelect={(cityName, IATA) => {
          dispatch(setToDestination({ cityName, IATA }));
          handleToDestSlide(false);
        }}
        title="Arrival"
        searchPlaceHolder="Arrival City or Airport"
      />
      <DatePickerDialogMobile
        open={
          states.visibleDepDatePickerDialog ||
          states.visibleReturnDatePickerDialog
        }
        handleClose={() => {
          handleDepDatePickerDialog(false);
          handleReturnDatePickerDialog(false);
        }}
        tripType={reduxStates.tripType}
        depDate={reduxStates.departureDate}
        returnDate={reduxStates.returnDate}
        onDepDateChange={(dateString) => dispatch(setDepartureDate(dateString))}
        onReturnDateChange={(dateString) => dispatch(setReturnDate(dateString))}
      />
      <PassengerSelectDialogMobile
        onValueChange={(ageType, value) =>
          dispatch(passengerCountAction(ageType, value, reduxStates))
        }
        adultCount={reduxStates.adultCount}
        childCount={reduxStates.childCount}
        infantCount={reduxStates.infantCount}
        open={states.visiblePassengerDialog}
        onClose={handlePassengerDialog.bind(null, false)}
        onConfirm={handlePassengerDialog.bind(null, false)}
      />
      <div className={styles.container}>
        {/* <CloseEditSearchBoxBtnMobile /> */}
        <InputBoxEditSearchDesktopMobile
          value={reduxStates.fromDestination.cityName}
          readOnly
          label="From"
          onClick={handleFromDestSlide.bind(null, true)}
        />
        <div className={styles.swapBtnContainer}>
          <button
            onClick={() => dispatch(swapDestinations())}
            className={styles.swapBtn}
          >
            <SwapVertIcon className={styles.swapIcon} />
          </button>
        </div>
        <InputBoxEditSearchDesktopMobile
          value={reduxStates.toDestination.cityName}
          readOnly
          label="To"
          onClick={handleToDestSlide.bind(null, true)}
        />
        <InputBoxEditSearchDesktopMobile
          readOnly
          value={moment(reduxStates.departureDate).format("ddd, DD MMM YYYY")}
          onClick={handleDepDatePickerDialog.bind(null, true)}
          label="Departure Date"
        />
        <InputBoxEditSearchDesktopMobile
          label="Return Date"
          value={moment(reduxStates.returnDate).format("ddd, DD MMM YYYY")}
          onClick={handleReturnDatePickerDialog.bind(null, true)}
          show={reduxStates.tripType === ROUND_TRIP}
          readOnly
        />
        <MyanmarCitizenRadioEditSearchDesktopMobile
          value={reduxStates.myanmarCitizen ? "1" : "0"}
          onChange={(value) => dispatch(setMyanmarCitizen(value))}
        />
        <TripTypeRadioEditSearchDesktopMobile
          value={reduxStates.tripType}
          onSelect={(value) => dispatch(setEditSearchTripType(value))}
        />
        <PassengerCountDisplayBoxEditSearchDesktopMobile
          onClick={handlePassengerDialog.bind(null, true)}
          adultCount={reduxStates.adultCount}
          childCount={reduxStates.childCount}
          infantCount={reduxStates.infantCount}
        />
        <SearchBtnEditSearch
          id={reduxStates.tripType === ROUND_TRIP ? "round-search-btn":"one-way-search-btn"}
          onClick={handleSearch.bind(null, reduxStates, navigate, companyName)}
        />
      </div>
    </>
  );
}

export default EditSearchMobileDesign;

import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import clientV3 from "./apollo/client";

import App from "./App";
import store from "./store";

/** Date Rage Picker Css Styles */
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "./index.scss";

ReactDOM.render(
  <ApolloProvider client={clientV3}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

import styles from "./TabBar.module.scss";

function TabBar({ currentTabIndex, setTabIndex }) {
  return (
    <div className={styles.tabContainer}>
      <button
        onClick={setTabIndex.bind(null, 0)}
        className={[
          styles.tab,
          currentTabIndex === 0 ? styles.tabActive : "",
        ].join(" ")}
      >
        <span>One Way</span>
      </button>
      <button
        onClick={setTabIndex.bind(null, 1)}
        className={[
          styles.tab,
          currentTabIndex === 1 ? styles.tabActive : "",
        ].join(" ")}
      >
        <span>Round Trip</span>
      </button>
    </div>
  );
}

export default TabBar;

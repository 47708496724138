import { TailSpin } from "react-loader-spinner";

import styles from "./Loading.module.scss";

function Loading() {
  return (
    <div className={styles.container}>
      <TailSpin height="60" width="60" color="grey" ariaLabel="loading" />
    </div>
  );
}

export default Loading;

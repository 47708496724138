import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";

import {EMAIL} from "../../common/constants/loginCredentialsLocalStorageKeys";
import { LOADING, ERROR, LOADED } from "../../common/constants/loadStatus";
import { COMPANY_ROUTE_NAME } from "../../common/constants/loginCredentialsLocalStorageKeys";

import { resetStates, setRememberMe } from "./redux/slice";

import loginAction from "./redux/actions/loginAction";

import LoginPageLayout from "./components/LoginPageLayout";
import LoginHeaderImgText from "./components/LoginHeaderImgText";
import LoginForm from "./components/LoginForm";

function Login() {
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const dispatch = useDispatch();
  const reduxStates = useSelector((state) => state.login);
  const navigate = useNavigate();

  const formSubmitHandler = (email, password) => {
    if (reduxStates.status === LOADING) return;
    dispatch(loginAction(email, password, reduxStates.rememberMe));
  };

  const onCloseHandler = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackBarVisible(false);
  };

  useEffect(() => {
    if (reduxStates.status === ERROR) return setSnackBarVisible(true);
    if (reduxStates.status === LOADED)
      return navigate(`/${localStorage.getItem(COMPANY_ROUTE_NAME)}`);
  }, [reduxStates.status]);

  // REDUX All redux states when compnent unmount
  useEffect(() => {
    dispatch(setRememberMe(localStorage.getItem(EMAIL) ? true:false));
    return () => dispatch(resetStates());
  }, []);

  return (
    <>
      <LoginPageLayout>
        <LoginHeaderImgText />
        <LoginForm
          isLoading={reduxStates.status === LOADING}
          isEmailError={reduxStates.emailError}
          emailErrorMessage={reduxStates.emailErrorMessage}
          isPasswordError={reduxStates.passwordError}
          passwordErrorMessage={reduxStates.passwordErrorMessage}
          onFormSubmit={formSubmitHandler}
          rememberMe={reduxStates.rememberMe}
          onRememberMeChange={(value)=>dispatch(setRememberMe(value))}
        />
      </LoginPageLayout>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarVisible}
        onClose={onCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={onCloseHandler}
          severity="error"
        >
          Incorrect Information.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;

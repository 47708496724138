import {SUNFAR_ROUTE_NAME,COLUMBUS_ROUTE_NAME} from "../constants/companyRouteNameConsts"

/**
 * Function to get Welcome text depends on login user
 * @param {String} loginCompanyName
 */
function getWelcomText(loginCompanyName){
    switch(loginCompanyName){
        case SUNFAR_ROUTE_NAME:
            return {firstText:"Satisfying your", secondText:"Travel needs"};
        case COLUMBUS_ROUTE_NAME:
            return {firstText:"Find your", secondText:"Flights Today!"};
        default:
            return {firstText:"", secondText:"Flymya Flight Aggregator"}
    }
}

export default getWelcomText;
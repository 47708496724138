import styles from "./TabButtons.module.scss";

function TabButtons({ index, flightDetailActiveStatus, onFlightDetailClick }) {
  return (
    <div className={styles.btnsContainer}>
      <button
        className={flightDetailActiveStatus ? styles.activeTabBtn : ""}
        onClick={() => onFlightDetailClick(index)}
      >
        <span>Flight</span> Details
      </button>
    </div>
  );
}

export default TabButtons;

import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

import styles from "./MyanmarCitizenRadioEditSearchDesktopMobile.module.scss";

function MyanmarCitizenRadioEditSearchDesktopMobile({ value, onChange }) {
  return (
    <div className={styles.container}>
      <label className={styles.labelText}>Myanmar Citizen</label>
      <RadioGroup onChange={(e) => onChange(e.target.value)} value={value} row>
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label={<label className={styles.radioLabelText}>Yes</label>}
        />
        <FormControlLabel
          style={{ marginLeft: "3px" }}
          value="0"
          control={<Radio size="small" />}
          label={<label className={styles.radioLabelText}>No</label>}
        />
      </RadioGroup>
    </div>
  );
}

export default MyanmarCitizenRadioEditSearchDesktopMobile;

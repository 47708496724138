import AirlinePartners from "./UI/AirlinePartners";
import FeaturedIn from "./UI/FeaturedIn";
import Informations from "./UI/Informations";

function Footer() {
  return (
    <>
      <AirlinePartners />
    </>
  );
}

export default Footer;

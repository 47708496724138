import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/404Logo.svg";

import styles from "./404.module.scss";

function NotFound() {
  const navigate = useNavigate();
  const handleClick = () => navigate("/");
  return (
    <div className={styles.container}>
      <img src={logo} alt="404 logo" />
      <h3>404</h3>
      <p>Sorry, the page you’re looking for doesn’t exist.</p>
      <button onClick={handleClick}>Go Back Home</button>
    </div>
  );
}

export default NotFound;

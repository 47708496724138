import { useState, useMemo } from "react";

import { AIRPORTS } from "../../../common/constants/airports";
import sortDestination from "../utils/sortDestinations";

function useDestinationSearch() {
  const popularDestinations = useMemo(() => [...AIRPORTS].slice(0, 4), []);
  const sortedDestinations = useMemo(() => sortDestination([...AIRPORTS]), []);

  const [result, setResult] = useState({
    popularDestinationsResult: popularDestinations,
    allDestinationsResult: sortedDestinations,
  });

  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);

    const allDestinationsResult = sortedDestinations.filter((i) =>
      i.CityName.trim()
        .toLocaleLowerCase()
        .includes(keyword.trim().toLocaleLowerCase())
    );

    const popularDestinationsResult = popularDestinations.filter((i) =>
      i.CityName.trim()
        .toLocaleLowerCase()
        .includes(keyword.trim().toLocaleLowerCase())
    );

    setResult({ allDestinationsResult, popularDestinationsResult });
  };

  return { result, searchKeyword, setSearchKeyword, handleSearch };
}

export default useDestinationSearch;

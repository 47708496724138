import { useParams } from "react-router-dom";

import Logout from "../features/logout";
import NavbarFlightList from "../features/navbarFlightList";
import FlightList from "../features/flightList/FlightList";
import EditSearch from "../features/flightSearch/EditSearch";

function FlightsList() {
  const routeParams = useParams();

  return (
    <>
      <NavbarFlightList
        companyName={routeParams.companyName}
        logout={<Logout />}
      />
      <FlightList
        editSearchComponent={
          <EditSearch companyName={routeParams.companyName} />
        }
      />
    </>
  );
}

export default FlightsList;

import { setExchangeRate } from "../slice";

import exchangeRateApi from "../../api/exchangeRateApi";

const getExchangeRateAction = () => async (dispatch) => {
  try {
    const response = await exchangeRateApi();
    dispatch(setExchangeRate(response.data.exchangeRatesByCode.selling));
  } catch (e) {
    console.log(e);
  }
};

export default getExchangeRateAction;

import { Popper, Paper } from "@mui/material";

import { ONE_WAY_TRIP, ROUND_TRIP } from "../constants/tripTypes";

import styles from "./TripTypeSelectPopperDesktop.module.scss";

function TripTypeSelectPopperDesktop({ anchorEl, onSelect, selectedType }) {
  return (
    <Popper
      style={{ zIndex: 2 }}
      placement="top-start"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      {({ placement }) => (
        <Paper
          style={{
            marginBottom: placement === "top-start" ? "10px" : 0,
            marginTop: placement === "bottom-start" ? "12px" : 0,
          }}
          className={styles.popperPaper}
        >
          <div className={styles.container}>
            <button
              className={[
                selectedType === ROUND_TRIP ? styles.active : "",
              ].join(" ")}
              onClick={() => onSelect(ROUND_TRIP)}
            >
              Round Trip
            </button>
            <button
              className={[
                selectedType === ONE_WAY_TRIP ? styles.active : "",
              ].join(" ")}
              onClick={() => onSelect(ONE_WAY_TRIP)}
            >
              One-way Flight
            </button>
          </div>
        </Paper>
      )}
    </Popper>
  );
}

export default TripTypeSelectPopperDesktop;

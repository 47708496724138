import AirBagan from "../../assets/images/airlinesLogos/airbagan.png";
import Airthanlwin from "../../assets/images/airlinesLogos/airthanlwin.png";
import Apex from "../../assets/images/airlinesLogos/apex.png";
import GMA from "../../assets/images/airlinesLogos/gma.png";
import Kbz from "../../assets/images/airlinesLogos/kbz.png";
import MAI from "../../assets/images/airlinesLogos/mai.png";
import MNA from "../../assets/images/airlinesLogos/mna.png";
import MYP from "../../assets/images/airlinesLogos/myp.png";
import YA from "../../assets/images/airlinesLogos/ya.png";

const AIRLINELOGOS = [
  {
    type: "myanmar national airlines",
    image: MNA,
  },
  {
    type: "golden myanmar airlines",
    image: GMA,
  },
  {
    type: "air kbz",
    image: Kbz,
  },
  {
    type: "air thanlwin",
    image: Airthanlwin,
  },
  {
    type: "mann yadanarpon airlines",
    image: MYP,
  },
  {
    type: "yangon airways",
    image: YA,
  },
  {
    type: "apex airlines",
    image: Apex,
  },
  {
    type: "air bagan",
    image: AirBagan,
  },
  {
    type: "myanmar airways international",
    image: MAI,
  },
];

export default AIRLINELOGOS;

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import styles from "./DetailsText.module.scss";

function DetailsText({
  fromDestination,
  toDestination,
  depDate,
  retDate,
  tripType,
  adultCount,
  childCount,
  infantCount,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.destText}>
        <span>{fromDestination}</span>
        <ArrowRightAltIcon className={styles.arrowIcon} fontSize="large" />
        <span>{toDestination}</span>
      </div>

      <div className={styles.dateTypePassText}>
        <span> {depDate} </span>
        {retDate && <span>&nbsp;-&nbsp;{retDate}</span>}
        <FiberManualRecordIcon className={styles.dotIcon} fontSize="small" />
        <span>{adultCount} Adult</span>
        {childCount ? <span>&nbsp;{childCount} Child</span> : <></>}
        {infantCount ? <span>&nbsp;{infantCount}Infant</span> : <></>}
        <FiberManualRecordIcon className={styles.dotIcon} fontSize="small" />
        <span>{tripType}</span>
      </div>
    </div>
  );
}

export default DetailsText;

import React from "react";
import { ClickAwayListener } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import styles from "./PassengerCountDisplayBoxEditSearchDesktopMobile.module.scss";

const PassengerCountDisplayBoxEditSearchDesktopMobile = React.forwardRef(
  (
    {
      adultCount,
      childCount,
      infantCount,
      onClick,
      onClickAway = () => {},
      children,
    },
    ref
  ) => {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={styles.container} ref={ref}>
          <label className={styles.labelText}>Passenger Type</label>
          <div onClick={onClick} className={styles.box}>
            <p>
              {adultCount} Adult, {childCount} Child, {infantCount} Infant
            </p>
            <ArrowDropDownIcon fontSize="small" className={styles.icon} />
          </div>
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);

export default PassengerCountDisplayBoxEditSearchDesktopMobile;
